export const contacts = [
    {
        id: 'cd5fa417-b667-482d-b208-798d9da3213c',
        avatar: 'images/avatars/male-01.jpg',
        background: 'images/cards/14-640x480.jpg',
        name: 'Dejesus Michael',
        emails: [
            {
                email: 'dejesusmichael@mail.org',
                label: 'Personal',
            },
            {
                email: 'michael.dejesus@vitricomp.io',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'bs',
                phoneNumber: '984 531 2468',
                label: 'Mobile',
            },
            {
                country: 'bs',
                phoneNumber: '806 470 2693',
                label: 'Work',
            },
        ],
        title: 'Track Service Worker',
        company: 'Vitricomp',
        birthday: '1975-01-10T12:00:00.000Z',
        address: '279 Independence Avenue, Calvary, Guam, PO4127',
        notes: '<p>Do incididunt cillum duis eu pariatur enim proident minim officia amet proident consequat consequat qui consequat magna magna occaecat aliquip culpa pariatur velit nisi nostrud irure eu ullamco exercitation sint.</p><p>Cillum deserunt laborum laborum quis nisi enim et aliquip labore excepteur in excepteur labore amet in ipsum ipsum nostrud deserunt lorem nisi voluptate dolor minim enim ut eu cupidatat enim.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'beec5287-ed50-4504-858a-5dc3f8ce6935',
        avatar: null,
        background: null,
        name: 'Dena Molina',
        emails: [
            {
                email: 'denamolina@mail.us',
                label: 'Personal',
            },
            {
                email: 'molina.dena@envire.tv',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'io',
                phoneNumber: '934 537 3180',
                label: 'Mobile',
            },
        ],
        title: 'Weather Analyst',
        company: 'Envire',
        birthday: '1994-12-05T12:00:00.000Z',
        address: '856 Woodside Avenue, Alfarata, Iowa, PO4992',
        notes: '<p>Consequat duis ullamco sint elit pariatur esse dolore nostrud consequat lorem duis sunt veniam ipsum exercitation eiusmod consequat nisi quis voluptate quis officia irure fugiat ex duis eu amet ex.</p><p>Irure est nisi dolor culpa sunt nulla irure lorem adipisicing non do consequat deserunt et ea eu non reprehenderit fugiat ex elit nulla sunt quis voluptate enim nulla aliquip veniam.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf',
        avatar: 'images/avatars/male-02.jpg',
        background: 'images/cards/15-640x480.jpg',
        name: 'Bernard Langley',
        emails: [
            {
                email: 'bernardlangley@mail.com',
                label: 'Personal',
            },
            {
                email: 'langley.bernard@boilcat.name',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'md',
                phoneNumber: '893 548 2862',
                label: 'Mobile',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Boilcat',
        birthday: '1988-05-26T12:00:00.000Z',
        address: '943 Adler Place, Hamilton, South Dakota, PO5592',
        notes: '<p>Est amet in adipisicing ex excepteur ullamco est lorem adipisicing veniam reprehenderit elit commodo cillum commodo eu officia fugiat id reprehenderit sunt mollit eiusmod dolor fugiat ad do esse aliquip.</p><p>Mollit amet adipisicing enim est est commodo sint et eu nulla in laboris ipsum aliqua elit aliqua adipisicing ea nulla nulla consectetur velit laborum labore ullamco eu sit consectetur velit.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '42a5da95-5e6d-42fd-a09d-de755d123a47',
        avatar: 'images/avatars/male-03.jpg',
        background: 'images/cards/16-640x480.jpg',
        name: 'Mclaughlin Steele',
        emails: [
            {
                email: 'mclaughlinsteele@mail.me',
                label: 'Personal',
            },
            {
                email: 'steele.mclaughlin@accel.info',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'va',
                phoneNumber: '830 484 3813',
                label: 'Mobile',
            },
            {
                country: 'va',
                phoneNumber: '999 475 2789',
                label: 'Work',
            },
            {
                country: 'va',
                phoneNumber: '933 406 3598',
                label: 'Home',
            },
        ],
        company: 'Accel',
        birthday: '1968-08-13T12:00:00.000Z',
        address: '334 Sandford Street, Savage, Virgin Islands, PO1858',
        notes: '<p>Consequat eu aliquip dolor non consequat laborum ad non labore cillum consectetur quis dolore do ea nulla incididunt proident ea eiusmod in do qui eiusmod et irure dolor ea adipisicing.</p><p>Reprehenderit occaecat nostrud ad aliquip commodo amet velit id ut minim dolor mollit mollit in eiusmod voluptate lorem nisi labore culpa elit proident laborum ipsum occaecat esse sint nostrud esse.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'a7806ced-03f1-4197-8b30-00bdd463366b',
        avatar: 'images/avatars/male-04.jpg',
        background: 'images/cards/17-640x480.jpg',
        name: 'Marsh Cochran',
        emails: [
            {
                email: 'marshcochran@mail.biz',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'tz',
                phoneNumber: '864 401 3980',
                label: 'Mobile',
            },
            {
                country: 'tz',
                phoneNumber: '956 546 2589',
                label: 'Work',
            },
        ],
        title: 'Fundraising Director',
        company: 'Xsports',
        birthday: '1983-12-22T12:00:00.000Z',
        address: '487 Hamilton Walk, Bergoo, American Samoa, PO5616',
        notes: '<p>Id eiusmod deserunt amet lorem commodo consequat nostrud magna aliquip ex et pariatur labore non elit ad ad nulla culpa reprehenderit enim magna aliqua enim pariatur occaecat sint do lorem.</p><p>Adipisicing ut est nulla nisi cupidatat consequat aliqua et esse in voluptate amet eiusmod ut esse ea do irure commodo aute culpa amet consequat id adipisicing et incididunt ut duis.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: 'f4ad15d9-5a24-463a-88ea-6189d6bb3a53',
        avatar: 'images/avatars/male-05.jpg',
        background: 'images/cards/18-640x480.jpg',
        name: 'Parrish Austin',
        emails: [
            {
                email: 'parrishaustin@mail.co.uk',
                label: 'Personal',
            },
            {
                email: 'austin.parrish@insource.net',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'lv',
                phoneNumber: '834 426 3574',
                label: 'Mobile',
            },
            {
                country: 'lv',
                phoneNumber: '816 573 3694',
                label: 'Work',
            },
            {
                country: 'lv',
                phoneNumber: '967 515 2009',
                label: 'Home',
            },
        ],
        title: 'Motor Winder',
        company: 'Insource',
        birthday: '1963-08-24T12:00:00.000Z',
        address: '610 Harbor Lane, Cascades, Minnesota, PO8639',
        notes: '<p>Cillum enim eiusmod dolor aliqua ipsum exercitation sint aliqua lorem dolore id velit sint velit labore cupidatat minim cupidatat elit est magna eu proident eiusmod non pariatur est esse pariatur.</p><p>Sint do enim officia velit pariatur excepteur commodo adipisicing labore elit velit velit id exercitation excepteur veniam reprehenderit sint nulla duis ad incididunt cillum in in labore laboris magna esse.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '780d0111-5e5c-4694-8d1d-0ea421971fbf',
        avatar: 'images/avatars/female-02.jpg',
        background: 'images/cards/19-640x480.jpg',
        name: 'Laverne Dodson',
        emails: [
            {
                email: 'lavernedodson@mail.ca',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'ar',
                phoneNumber: '964 417 2318',
                label: 'Mobile',
            },
            {
                country: 'ar',
                phoneNumber: '830 410 2506',
                label: 'Work',
            },
        ],
        title: 'Television News Producer',
        company: 'Lovepad',
        birthday: '1973-09-25T12:00:00.000Z',
        address: '428 Newport Street, Neahkahnie, Arkansas, PO8324',
        notes: '<p>Incididunt lorem proident est anim amet nulla do nulla ea anim ullamco ea amet voluptate laboris do elit elit consequat in esse in dolor enim irure ut irure ad commodo.</p><p>Aliqua dolore nulla sunt ad nostrud aute labore occaecat non amet nulla adipisicing sint eu lorem velit sint do sint adipisicing esse adipisicing anim culpa quis dolor non magna ea.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: 'bf172879-423a-4fd6-8df3-6d1938bbfe1f',
        avatar: 'images/avatars/male-06.jpg',
        background: 'images/cards/20-640x480.jpg',
        name: 'Edwards Mckenzie',
        emails: [
            {
                email: 'edwardsmckenzie@mail.org',
                label: 'Personal',
            },
            {
                email: 'mckenzie.edwards@bugsall.io',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'pe',
                phoneNumber: '934 519 2903',
                label: 'Mobile',
            },
            {
                country: 'pe',
                phoneNumber: '989 489 3662',
                label: 'Work',
            },
            {
                country: 'pe',
                phoneNumber: '813 461 2790',
                label: 'Home',
            },
        ],
        title: 'Legal Assistant',
        company: 'Bugsall',
        birthday: '1988-07-27T12:00:00.000Z',
        address: '384 Polhemus Place, Dalton, Palau, PO6038',
        notes: '<p>Eu veniam consectetur eiusmod anim sint anim consectetur do consectetur aliqua cillum proident fugiat do in aliqua ipsum id consequat commodo qui officia adipisicing ullamco occaecat laboris proident incididunt exercitation.</p><p>Velit ullamco magna aute proident irure ut magna ullamco labore dolor deserunt deserunt tempor fugiat ex ullamco do sunt veniam reprehenderit officia elit duis sint ut proident pariatur est reprehenderit.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: '1eaa3213-ece2-4ba6-8e15-eb36ca388f50',
        avatar: 'images/avatars/female-03.jpg',
        background: 'images/cards/21-640x480.jpg',
        name: 'Trudy Berg',
        emails: [
            {
                email: 'trudyberg@mail.us',
                label: 'Personal',
            },
            {
                email: 'berg.trudy@satiance.tv',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'ls',
                phoneNumber: '912 539 2770',
                label: 'Mobile',
            },
        ],
        title: 'Meteorologist',
        company: 'Satiance',
        birthday: '1989-12-15T12:00:00.000Z',
        address: '945 Jerome Avenue, Riceville, North Carolina, PO1625',
        notes: '<p>Excepteur ullamco aute aliqua reprehenderit ullamco do anim ut ut veniam et ut et ut commodo aliqua consequat occaecat fugiat dolor labore proident ipsum ad culpa est cillum aliqua reprehenderit.</p><p>Amet aliqua sint laboris in aute nostrud voluptate tempor ea tempor laborum tempor culpa dolore aliqua nulla dolore ad enim id cupidatat nostrud nostrud amet non velit id fugiat lorem.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
        avatar: 'images/avatars/female-04.jpg',
        background: 'images/cards/22-640x480.jpg',
        name: 'Elsie Melendez',
        emails: [
            {
                email: 'elsiemelendez@mail.com',
                label: 'Personal',
            },
            {
                email: 'melendez.elsie@chillium.name',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'tg',
                phoneNumber: '907 515 3007',
                label: 'Mobile',
            },
            {
                country: 'tg',
                phoneNumber: '967 534 2803',
                label: 'Work',
            },
        ],
        title: 'Fundraising Director',
        company: 'Chillium',
        birthday: '1980-06-28T12:00:00.000Z',
        address: '428 Varanda Place, Veyo, Oklahoma, PO6188',
        notes: '<p>Laboris commodo consequat duis dolor ullamco nisi sunt ipsum nisi elit dolore aute sint tempor qui ad sit aliqua laboris consequat dolore aliqua est deserunt irure cillum tempor ut veniam.</p><p>Eiusmod nulla ex esse in deserunt consectetur non qui cillum reprehenderit magna sit ipsum lorem aute consequat sint magna id laboris velit adipisicing non ipsum ipsum sint velit ex non.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: 'efae92cc-3bd1-4c6a-a395-b6760c69bd55',
        avatar: 'images/avatars/male-07.jpg',
        background: 'images/cards/23-640x480.jpg',
        name: 'Lamb Underwood',
        emails: [
            {
                email: 'lambunderwood@mail.me',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'pf',
                phoneNumber: '855 517 2767',
                label: 'Mobile',
            },
            {
                country: 'pf',
                phoneNumber: '906 442 3593',
                label: 'Work',
            },
            {
                country: 'pf',
                phoneNumber: '905 402 2121',
                label: 'Home',
            },
        ],
        title: 'Legal Assistant',
        company: 'Exotechno',
        birthday: '1990-07-26T12:00:00.000Z',
        address: '609 Greenpoint Avenue, Beason, Vermont, PO5229',
        notes: '<p>Exercitation tempor laboris dolor deserunt nulla et nisi ullamco minim duis sint nulla sint deserunt irure excepteur nostrud ipsum duis enim sit exercitation eiusmod tempor commodo excepteur mollit cupidatat fugiat.</p><p>Deserunt est dolore nulla laborum consequat veniam elit lorem do exercitation incididunt ea ad laboris lorem ipsum ex incididunt nostrud ipsum laborum et nostrud minim aute velit incididunt quis quis.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: 'bde636a7-c3d2-4bff-939a-aab11df1516b',
        avatar: null,
        background: null,
        name: 'Tessa Valdez',
        emails: [
            {
                email: 'tessavaldez@mail.info',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'dz',
                phoneNumber: '892 430 2631',
                label: 'Mobile',
            },
            {
                country: 'dz',
                phoneNumber: '997 525 2354',
                label: 'Work',
            },
            {
                country: 'dz',
                phoneNumber: '907 472 2857',
                label: 'Home',
            },
        ],
        title: 'Banker Mason',
        company: 'Securia',
        birthday: '1994-01-10T12:00:00.000Z',
        address: '183 Crosby Avenue, Blanco, Mississippi, PO3463',
        notes: '<p>Mollit qui amet in esse ipsum nostrud cupidatat occaecat proident aliquip non mollit commodo ex labore enim culpa dolor aute occaecat cillum sit excepteur tempor culpa nostrud nulla qui commodo.</p><p>Labore nulla id excepteur non velit adipisicing tempor reprehenderit cillum sint do consectetur laboris ut proident pariatur quis aute ad dolor quis labore labore nostrud sunt elit proident enim aliqua.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '6519600a-5eaa-45f8-8bed-c46fddb3b26a',
        avatar: 'images/avatars/male-08.jpg',
        background: 'images/cards/24-640x480.jpg',
        name: 'Mcleod Wagner',
        emails: [
            {
                email: 'mcleodwagner@mail.biz',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'at',
                phoneNumber: '977 590 2773',
                label: 'Mobile',
            },
            {
                country: 'at',
                phoneNumber: '828 496 3813',
                label: 'Work',
            },
            {
                country: 'at',
                phoneNumber: '831 432 2512',
                label: 'Home',
            },
        ],
        company: 'Inrt',
        birthday: '1980-12-03T12:00:00.000Z',
        address: '736 Glen Street, Kaka, West Virginia, PO9350',
        notes: '<p>Laboris consequat est anim quis quis eiusmod ipsum non quis fugiat anim culpa non elit mollit pariatur veniam nisi irure velit dolore dolor proident nisi deserunt culpa nisi et laborum.</p><p>Eiusmod eu esse ipsum voluptate excepteur ipsum et proident cupidatat sint sunt aliquip lorem culpa esse et dolor fugiat sit est id consectetur sint et ea pariatur occaecat nulla irure.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '6d80a6f6-2884-4ac4-9c73-06b82c220017',
        avatar: 'images/avatars/female-06.jpg',
        background: 'images/cards/25-640x480.jpg',
        name: 'Kristie Hall',
        emails: [
            {
                email: 'kristiehall@mail.co.uk',
                label: 'Personal',
            },
            {
                email: 'hall.kristie@austech.net',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'tn',
                phoneNumber: '841 530 3641',
                label: 'Mobile',
            },
            {
                country: 'tn',
                phoneNumber: '941 410 3743',
                label: 'Work',
            },
            {
                country: 'tn',
                phoneNumber: '938 599 3850',
                label: 'Home',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Austech',
        birthday: '1975-08-31T12:00:00.000Z',
        address: '547 Revere Place, Hoehne, New Hampshire, PO2125',
        notes: '<p>Duis incididunt minim nisi sit qui dolor aliquip quis ipsum id amet occaecat sit ullamco minim velit est eiusmod anim proident consectetur non reprehenderit ea reprehenderit dolore in nisi eiusmod.</p><p>Ut commodo aliqua non ut proident velit et commodo voluptate eu mollit dolor veniam ipsum velit aute esse est adipisicing id aliqua nostrud nostrud nisi enim officia eiusmod in enim.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '35190d23-036e-44ef-b545-cc744c626edd',
        avatar: 'images/avatars/female-07.jpg',
        background: 'images/cards/26-640x480.jpg',
        name: 'Shannon Kennedy',
        emails: [
            {
                email: 'shannonkennedy@mail.ca',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'gb',
                phoneNumber: '899 508 2992',
                label: 'Mobile',
            },
            {
                country: 'gb',
                phoneNumber: '834 499 3354',
                label: 'Work',
            },
            {
                country: 'gb',
                phoneNumber: '834 526 3388',
                label: 'Home',
            },
        ],
        title: 'Gas Meter Mechanic',
        company: 'Eventix',
        birthday: '1994-09-07T12:00:00.000Z',
        address: '480 Chase Court, Edinburg, Kansas, PO5357',
        notes: '<p>Lorem ex amet anim anim qui consequat ullamco consectetur et voluptate in velit dolore culpa pariatur amet enim ut non magna duis qui excepteur esse ullamco velit fugiat aute dolor.</p><p>Reprehenderit ullamco veniam sit laborum nulla sunt excepteur eiusmod anim eu ullamco tempor est qui adipisicing sit fugiat voluptate minim non incididunt quis ipsum et exercitation officia laborum incididunt nostrud.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: 'b018c194-68ec-4915-ab56-e9f3bd2d98db',
        avatar: 'images/avatars/female-08.jpg',
        background: 'images/cards/27-640x480.jpg',
        name: 'Martha Swanson',
        emails: [
            {
                email: 'marthaswanson@mail.org',
                label: 'Personal',
            },
            {
                email: 'swanson.martha@sequitur.io',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'gb',
                phoneNumber: '844 480 3309',
                label: 'Mobile',
            },
            {
                country: 'gb',
                phoneNumber: '981 591 3239',
                label: 'Work',
            },
            {
                country: 'gb',
                phoneNumber: '923 484 3147',
                label: 'Home',
            },
        ],
        title: 'Short Story Writer',
        company: 'Sequitur',
        birthday: '1993-12-31T12:00:00.000Z',
        address: '595 Howard Place, Convent, Rhode Island, PO6993',
        notes: '<p>Lorem nostrud cillum non cillum nisi eu labore anim ipsum consequat consectetur sunt ipsum ipsum ad culpa laborum in ea exercitation quis voluptate velit id elit labore cillum cillum consectetur.</p><p>Ullamco ullamco nostrud aute pariatur nulla officia proident magna laborum dolor reprehenderit ullamco in reprehenderit veniam aliqua elit magna voluptate amet ut minim in labore irure culpa consequat sit pariatur.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: 'b7c355e9-e003-467e-82d2-4f6978c1a696',
        avatar: 'images/avatars/female-09.jpg',
        background: 'images/cards/28-640x480.jpg',
        name: 'Jacklyn Morgan',
        emails: [
            {
                email: 'jacklynmorgan@mail.us',
                label: 'Personal',
            },
            {
                email: 'morgan.jacklyn@shopabout.tv',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'so',
                phoneNumber: '974 542 2061',
                label: 'Mobile',
            },
        ],
        title: 'Animal Sitter',
        company: 'Shopabout',
        birthday: '1976-09-30T12:00:00.000Z',
        address: '971 Conover Street, Statenville, Louisiana, PO6622',
        notes: '<p>Pariatur fugiat labore aliquip aute in adipisicing veniam et consequat magna nulla laboris eiusmod eu esse cupidatat ipsum amet sint est anim lorem consequat eiusmod sit aliquip consequat nisi duis.</p><p>Est esse excepteur non amet reprehenderit cillum ullamco ex excepteur laboris excepteur dolor magna enim consequat lorem commodo ipsum elit ea veniam non quis id nisi esse tempor enim ut.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: 'cfa07b7c-93d1-42e7-9592-493d9efc78ae',
        avatar: 'images/avatars/female-10.jpg',
        background: 'images/cards/29-640x480.jpg',
        name: 'Tonya Bowers',
        emails: [
            {
                email: 'tonyabowers@mail.com',
                label: 'Personal',
            },
            {
                email: 'bowers.tonya@tourmania.name',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'tv',
                phoneNumber: '922 585 2914',
                label: 'Mobile',
            },
            {
                country: 'tv',
                phoneNumber: '913 538 2961',
                label: 'Work',
            },
        ],
        title: 'Track Service Worker',
        company: 'Tourmania',
        birthday: '1976-06-14T12:00:00.000Z',
        address: '197 Marconi Place, Welda, Delaware, PO6061',
        notes: '<p>Aliqua ea dolor est enim ipsum esse pariatur tempor nulla excepteur aliquip irure fugiat reprehenderit adipisicing ex tempor proident voluptate dolore ea dolore nostrud id incididunt culpa in do occaecat.</p><p>Aute fugiat magna velit enim in duis duis elit ipsum excepteur reprehenderit do ipsum qui cillum aliquip ut occaecat do ea et adipisicing cupidatat voluptate non elit ad aliqua ad.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: '00feeb63-c83a-4655-a37e-a07da10cfa1c',
        avatar: 'images/avatars/female-11.jpg',
        background: 'images/cards/30-640x480.jpg',
        name: 'Latonya Cruz',
        emails: [
            {
                email: 'latonyacruz@mail.me',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'tm',
                phoneNumber: '981 508 2080',
                label: 'Mobile',
            },
            {
                country: 'tm',
                phoneNumber: '817 425 2052',
                label: 'Work',
            },
            {
                country: 'tm',
                phoneNumber: '939 434 3805',
                label: 'Home',
            },
        ],
        title: 'Motor Winder',
        company: 'Zilch',
        birthday: '1967-11-28T12:00:00.000Z',
        address: '775 Dahill Road, Iberia, California, PO2169',
        notes: '<p>Ut occaecat tempor deserunt proident enim ex ullamco ex aliquip mollit aute reprehenderit in occaecat anim aliquip ea laboris anim laboris do non aute aute ea laboris magna sunt sit.</p><p>Ullamco in in minim culpa eiusmod amet consequat consequat magna nisi cillum occaecat irure officia voluptate et eu duis officia nostrud culpa non eiusmod anim sint et anim enim voluptate.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '142abf21-e635-4a7d-9330-e57f66adcdbe',
        avatar: 'images/avatars/female-12.jpg',
        background: 'images/cards/31-640x480.jpg',
        name: 'Evangelina Mcclain',
        emails: [
            {
                email: 'evangelinamcclain@mail.info',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'ck',
                phoneNumber: '992 583 3187',
                label: 'Mobile',
            },
            {
                country: 'ck',
                phoneNumber: '881 472 3297',
                label: 'Work',
            },
            {
                country: 'ck',
                phoneNumber: '846 477 3596',
                label: 'Home',
            },
        ],
        title: 'Congressional Representative',
        company: 'Straloy',
        birthday: '1976-02-15T12:00:00.000Z',
        address: '305 Columbia Street, Dupuyer, Puerto Rico, PO8744',
        notes: '<p>Proident nulla culpa magna nostrud do aliqua ullamco sit culpa ullamco eu amet culpa laborum enim fugiat non ad quis esse pariatur exercitation lorem incididunt exercitation aliquip labore minim adipisicing.</p><p>Sint ea voluptate tempor irure consequat aute laboris exercitation id minim voluptate aliquip tempor occaecat elit incididunt laboris enim labore sit aute sunt cillum ipsum ad laboris nostrud dolor excepteur.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: 'e4f255a3-b5dd-45a7-975f-c399604a399a',
        avatar: 'images/avatars/male-09.jpg',
        background: 'images/cards/32-640x480.jpg',
        name: 'Herring Gonzales',
        emails: [
            {
                email: 'herringgonzales@mail.biz',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'ai',
                phoneNumber: '995 411 2513',
                label: 'Mobile',
            },
            {
                country: 'ai',
                phoneNumber: '839 492 2760',
                label: 'Work',
            },
        ],
        title: 'Gas Meter Mechanic',
        company: 'Cubix',
        birthday: '1995-02-16T12:00:00.000Z',
        address: '195 Brooklyn Road, Jeff, Marshall Islands, PO2943',
        notes: '<p>Ex nulla nisi do cillum consequat amet incididunt eu minim eu ut excepteur ad anim minim aliquip ullamco fugiat labore esse aliquip ea incididunt incididunt nisi officia consectetur dolore minim.</p><p>Et dolor consectetur anim deserunt laborum eu lorem et in nisi et officia nostrud fugiat deserunt aute irure ullamco officia fugiat voluptate exercitation ut deserunt officia nostrud tempor velit pariatur.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'ab4f712d-d712-41a8-b567-be4c66c349a3',
        avatar: 'images/avatars/female-13.jpg',
        background: 'images/cards/33-640x480.jpg',
        name: 'Alyce Cash',
        emails: [
            {
                email: 'alycecash@mail.co.uk',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'ht',
                phoneNumber: '969 499 3077',
                label: 'Mobile',
            },
            {
                country: 'ht',
                phoneNumber: '907 513 2784',
                label: 'Work',
            },
        ],
        title: 'Weather Analyst',
        company: 'Qnekt',
        birthday: '1973-12-19T12:00:00.000Z',
        address: '964 Henry Street, Eureka, Indiana, PO1035',
        notes: '<p>Non proident pariatur nostrud dolor incididunt occaecat amet officia sunt magna anim dolor labore culpa ut laborum id incididunt officia amet mollit anim ea proident laboris non incididunt incididunt sint.</p><p>Nulla minim consectetur nostrud magna anim irure consectetur labore cupidatat laborum reprehenderit et et adipisicing in qui elit ipsum reprehenderit esse nisi non ipsum exercitation sunt eu elit velit fugiat.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '5d067800-c301-46c6-a7f7-28dc89d9a554',
        avatar: null,
        background: null,
        name: 'Kristine Pacheco',
        emails: [
            {
                email: 'kristinepacheco@mail.net',
                label: 'Personal',
            },
            {
                email: 'pacheco.kristine@vurbo.ca',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'mm',
                phoneNumber: '977 516 2492',
                label: 'Mobile',
            },
        ],
        title: 'Short Story Writer',
        company: 'Vurbo',
        birthday: '1985-10-22T12:00:00.000Z',
        address: '622 Dodworth Street, Rose, Arizona, PO9530',
        notes: '<p>Lorem laboris excepteur magna pariatur occaecat voluptate pariatur cillum exercitation anim enim elit laborum reprehenderit laboris ad velit ut ipsum irure id ullamco minim sint ipsum occaecat esse tempor ea.</p><p>Pariatur non labore cillum consectetur aute voluptate sint adipisicing nisi laborum culpa nisi elit et amet dolor incididunt velit ex laboris ea reprehenderit eiusmod qui esse veniam labore ea sit.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: 'c500255a-1173-47d0-a0e4-4944d48fc12a',
        avatar: 'images/avatars/male-10.jpg',
        background: 'images/cards/34-640x480.jpg',
        name: 'English Haney',
        emails: [
            {
                email: 'englishhaney@mail.org',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'lb',
                phoneNumber: '989 567 3834',
                label: 'Mobile',
            },
        ],
        title: 'Meteorologist',
        company: 'Photobin',
        birthday: '1969-09-05T12:00:00.000Z',
        address: '579 Pooles Lane, Belleview, Montana, PO4106',
        notes: '<p>Incididunt labore sunt ullamco in deserunt dolore labore voluptate adipisicing eu id duis eiusmod elit ea ad cillum culpa excepteur labore fugiat excepteur ea culpa labore sit id dolor ullamco.</p><p>Eu eu ex dolore proident nostrud et minim lorem nulla lorem nulla duis velit voluptate nisi cillum anim minim amet dolore officia id cillum in cupidatat ipsum veniam velit dolor.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'b62359fd-f2a8-46e6-904e-31052d1cd675',
        avatar: 'images/avatars/male-11.jpg',
        background: 'images/cards/35-640x480.jpg',
        name: 'Joseph Strickland',
        emails: [
            {
                email: 'josephstrickland@mail.io',
                label: 'Personal',
            },
            {
                email: 'strickland.joseph@bytrex.us',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'jo',
                phoneNumber: '990 450 2729',
                label: 'Mobile',
            },
        ],
        title: 'Hotel Manager',
        company: 'Bytrex',
        birthday: '1991-09-08T12:00:00.000Z',
        address: '844 Ellery Street, Hondah, Texas, PO1272',
        notes: '<p>Excepteur consequat magna laborum dolore ut laborum ea excepteur ad officia mollit exercitation sunt tempor amet ex ipsum aliquip cillum mollit amet laborum voluptate ipsum sit esse duis eiusmod adipisicing.</p><p>Non tempor ad pariatur adipisicing excepteur est pariatur aute et velit lorem ut est eu voluptate pariatur ea consectetur excepteur sunt reprehenderit id irure aliqua tempor anim id voluptate culpa.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: '16b9e696-ea95-4dd8-86c4-3caf705a1dc6',
        avatar: 'images/avatars/male-12.jpg',
        background: 'images/cards/36-640x480.jpg',
        name: 'Nunez Faulkner',
        emails: [
            {
                email: 'nunezfaulkner@mail.tv',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'xk',
                phoneNumber: '909 552 3327',
                label: 'Mobile',
            },
        ],
        title: 'Hotel Manager',
        company: 'Buzzopia',
        birthday: '1982-01-23T12:00:00.000Z',
        address: '614 Herkimer Court, Darrtown, Nebraska, PO9308',
        notes: '<p>Culpa labore ullamco veniam est ullamco ipsum culpa excepteur esse esse aliqua nulla ullamco nulla amet consequat tempor aute exercitation do eu do ullamco elit excepteur est anim nisi excepteur.</p><p>Cillum eiusmod cupidatat officia ipsum ullamco adipisicing cillum adipisicing sint exercitation non enim consectetur est esse tempor fugiat sit eiusmod in exercitation enim quis duis dolor amet consequat pariatur dolor.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: '19662ecf-0686-4aad-a46c-24b552eb2ff5',
        avatar: 'images/avatars/female-15.jpg',
        background: 'images/cards/14-640x480.jpg',
        name: 'Juana Morrow',
        emails: [
            {
                email: 'juanamorrow@mail.com',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'ee',
                phoneNumber: '868 438 3943',
                label: 'Mobile',
            },
        ],
        title: 'Meteorologist',
        company: 'Lyria',
        birthday: '1992-03-29T12:00:00.000Z',
        address: '663 Drew Street, Juntura, Georgia, PO9857',
        notes: '<p>Mollit et amet qui incididunt officia anim est in consectetur qui anim qui labore ea mollit veniam adipisicing ex magna commodo mollit adipisicing sunt commodo laboris labore aliquip deserunt est.</p><p>Cupidatat ut cillum anim reprehenderit ea magna enim fugiat proident anim esse lorem lorem commodo cupidatat pariatur qui commodo nulla aliqua nisi labore in adipisicing minim excepteur do eu amet.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '26dfe954-8bf3-45ee-b285-1d0a88c8d3ea',
        avatar: 'images/avatars/male-13.jpg',
        background: 'images/cards/15-640x480.jpg',
        name: 'Lara Gaines',
        emails: [
            {
                email: 'laragaines@mail.name',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'mr',
                phoneNumber: '891 498 2043',
                label: 'Mobile',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Acruex',
        birthday: '1961-06-07T12:00:00.000Z',
        address: '762 Troutman Street, Drummond, Oregon, PO6973',
        notes: '<p>Laboris dolor incididunt eiusmod deserunt officia labore eu est nulla velit id ex veniam qui fugiat velit irure reprehenderit dolor proident aliquip culpa nisi magna occaecat do nostrud cillum lorem.</p><p>Sit consequat laboris culpa quis laborum lorem ullamco occaecat labore duis ea et consequat pariatur reprehenderit excepteur excepteur exercitation sunt enim amet adipisicing laborum incididunt dolor aliquip culpa ea laboris.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: 'd6462af2-c488-4de7-9b26-3845bd2983f9',
        avatar: 'images/avatars/male-14.jpg',
        background: 'images/cards/16-640x480.jpg',
        name: 'Johnston Riddle',
        emails: [
            {
                email: 'johnstonriddle@mail.me',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bt',
                phoneNumber: '979 541 2691',
                label: 'Mobile',
            },
            {
                country: 'bt',
                phoneNumber: '909 407 3887',
                label: 'Work',
            },
            {
                country: 'bt',
                phoneNumber: '864 557 3128',
                label: 'Home',
            },
        ],
        title: 'Hotel Manager',
        company: 'Xleen',
        birthday: '1972-09-13T12:00:00.000Z',
        address:
            '674 Bryant Street, Grahamtown, Federated States Of Micronesia, PO2757',
        notes: '<p>Velit consequat elit anim qui eu elit aliquip consectetur aliqua cupidatat lorem laboris dolor qui ad laborum adipisicing adipisicing consequat et nostrud ullamco consequat dolore deserunt irure do aliquip non.</p><p>Ipsum commodo voluptate qui ex ullamco amet do ex dolore quis cupidatat ut anim sunt dolore excepteur anim do dolor aliqua ex aute esse eiusmod sint laborum consequat laboris cillum.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: 'a1723c04-69fe-4573-a135-6645658afe76',
        avatar: null,
        background: null,
        name: 'Vargas Gardner',
        emails: [
            {
                email: 'vargasgardner@mail.info',
                label: 'Personal',
            },
            {
                email: 'gardner.vargas@cosmosis.biz',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'bi',
                phoneNumber: '855 456 2754',
                label: 'Mobile',
            },
        ],
        title: 'Bindery Machine Operator',
        company: 'Cosmosis',
        birthday: '1979-10-21T12:00:00.000Z',
        address: '869 Seton Place, Chemung, Maine, PO8109',
        notes: '<p>Amet non anim ex ullamco pariatur ullamco laboris eiusmod ut magna nisi amet incididunt sunt anim nisi qui ut ex sunt adipisicing consequat deserunt qui mollit duis anim quis veniam.</p><p>Magna ut id duis qui ea proident quis officia lorem commodo et et proident dolore qui quis incididunt nulla incididunt ut aliqua veniam est adipisicing adipisicing reprehenderit ad velit incididunt.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '823e6166-c0c8-4373-9270-8a0d17489a08',
        avatar: 'images/avatars/male-16.jpg',
        background: 'images/cards/17-640x480.jpg',
        name: 'Mccall Day',
        emails: [
            {
                email: 'mccallday@mail.co.uk',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'se',
                phoneNumber: '993 504 3286',
                label: 'Mobile',
            },
            {
                country: 'se',
                phoneNumber: '924 434 2238',
                label: 'Work',
            },
            {
                country: 'se',
                phoneNumber: '816 466 2634',
                label: 'Home',
            },
        ],
        title: 'Historiographer',
        company: 'Nipaz',
        birthday: '1964-03-05T12:00:00.000Z',
        address: '854 Hanover Place, Harleigh, New Jersey, PO9459',
        notes: '<p>Ea occaecat nisi cillum officia in velit ipsum reprehenderit ex fugiat fugiat ad velit pariatur ullamco sint in elit quis aute id cupidatat nostrud quis culpa aliquip id officia excepteur.</p><p>Ea ut consequat sit ullamco do pariatur quis officia ad ipsum quis nisi in nulla incididunt esse pariatur amet qui ullamco consectetur dolor voluptate sit qui mollit reprehenderit reprehenderit amet.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: '2c37ed00-427a-46d7-8f8f-d711c768d1ee',
        avatar: 'images/avatars/male-17.jpg',
        background: 'images/cards/18-640x480.jpg',
        name: 'Silva Foster',
        emails: [
            {
                email: 'silvafoster@mail.net',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bn',
                phoneNumber: '916 511 3837',
                label: 'Mobile',
            },
            {
                country: 'bn',
                phoneNumber: '949 564 3247',
                label: 'Work',
            },
        ],
        title: 'Insurance Analyst',
        company: 'Extrawear',
        birthday: '1980-04-29T12:00:00.000Z',
        address: '137 Bridge Street, Sisquoc, District Of Columbia, PO4105',
        notes: '<p>Ipsum velit est do velit do deserunt cupidatat officia duis laborum veniam sunt in ex reprehenderit esse ex ad aute anim duis ut sunt reprehenderit occaecat ut nostrud eu minim.</p><p>Aliqua consequat adipisicing adipisicing aliquip voluptate fugiat eu amet nostrud id proident non nisi fugiat velit nostrud ea officia non laboris magna cillum exercitation culpa eiusmod mollit fugiat et lorem.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '944764c0-b261-4428-9188-bbd3022d66a8',
        avatar: 'images/avatars/female-16.jpg',
        background: 'images/cards/19-640x480.jpg',
        name: 'Cathryn Snider',
        emails: [
            {
                email: 'cathrynsnider@mail.ca',
                label: 'Personal',
            },
            {
                email: 'snider.cathryn@phormula.org',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'na',
                phoneNumber: '896 471 3036',
                label: 'Mobile',
            },
            {
                country: 'na',
                phoneNumber: '851 491 3567',
                label: 'Work',
            },
            {
                country: 'na',
                phoneNumber: '805 487 2016',
                label: 'Home',
            },
        ],
        title: 'Short Story Writer',
        company: 'Phormula',
        birthday: '1981-06-09T12:00:00.000Z',
        address: '528 Glenmore Avenue, Elrama, Illinois, PO2952',
        notes: '<p>Ea enim exercitation lorem excepteur officia nulla culpa culpa nisi veniam quis non duis exercitation labore commodo et occaecat reprehenderit ex velit exercitation commodo cupidatat amet veniam mollit magna consectetur.</p><p>Voluptate consectetur eu id eiusmod anim reprehenderit incididunt duis veniam tempor cillum ea esse tempor do laborum dolore sint ea duis incididunt in do aliqua voluptate incididunt officia excepteur do.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'f2b3c756-5ad2-4d4b-aee5-b32c91457128',
        avatar: null,
        background: null,
        name: 'Mooney Cantrell',
        emails: [
            {
                email: 'mooneycantrell@mail.io',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bh',
                phoneNumber: '915 577 3020',
                label: 'Mobile',
            },
            {
                country: 'bh',
                phoneNumber: '923 431 3594',
                label: 'Work',
            },
        ],
        title: 'Fundraising Director',
        company: 'Crustatia',
        birthday: '1968-12-07T12:00:00.000Z',
        address: '277 Coventry Road, Fairforest, Nevada, PO6031',
        notes: '<p>Lorem mollit dolore nostrud sunt id anim veniam labore duis eiusmod duis fugiat aliqua occaecat do labore culpa consectetur consectetur sunt amet tempor incididunt tempor esse sunt id elit non.</p><p>Laborum mollit ullamco quis ad culpa nisi sit nisi veniam minim adipisicing sint eiusmod velit amet minim aliquip nulla eiusmod nulla laboris quis proident in adipisicing aute et ea anim.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: '54b1c201-4b2b-4be0-ad70-a6413e9628cd',
        avatar: 'images/avatars/female-17.jpg',
        background: 'images/cards/20-640x480.jpg',
        name: 'Saundra Murphy',
        emails: [
            {
                email: 'saundramurphy@mail.us',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'mt',
                phoneNumber: '902 529 2999',
                label: 'Mobile',
            },
        ],
        title: 'Dental Laboratory Worker',
        company: 'Zilencio',
        birthday: '1983-11-07T12:00:00.000Z',
        address: '557 Monroe Street, Mayfair, Maryland, PO7200',
        notes: '<p>Fugiat mollit sunt aliquip consectetur ipsum ut aliqua id ex laboris labore id elit nulla irure id aute pariatur do officia proident eiusmod proident reprehenderit dolor non dolor laborum nulla.</p><p>Pariatur reprehenderit incididunt voluptate enim aliqua laborum anim veniam pariatur irure exercitation non dolore velit et ex culpa lorem ipsum mollit eu sint duis aliquip elit amet consectetur velit minim.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'faf979c7-a13b-445a-b30a-08845f5fa90e',
        avatar: 'images/avatars/female-18.jpg',
        background: 'images/cards/21-640x480.jpg',
        name: 'Enid Sparks',
        emails: [
            {
                email: 'enidsparks@mail.tv',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bh',
                phoneNumber: '813 410 3258',
                label: 'Mobile',
            },
            {
                country: 'bh',
                phoneNumber: '877 501 2767',
                label: 'Work',
            },
        ],
        title: 'Historiographer',
        company: 'Skybold',
        birthday: '1984-05-04T12:00:00.000Z',
        address: '219 Village Court, Keyport, Alabama, PO7776',
        notes: '<p>Velit enim anim est aliqua consequat exercitation velit quis magna est incididunt ipsum minim minim nulla adipisicing ad eiusmod id veniam eiusmod sit elit est pariatur velit ea laborum anim.</p><p>Ad lorem ea nisi irure id consequat ullamco nisi nostrud dolore officia ipsum veniam velit minim pariatur culpa culpa esse minim adipisicing sit labore commodo aute excepteur non do in.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '2bfa2be5-7688-48d5-b5ac-dc0d9ac97f14',
        avatar: null,
        background: null,
        name: 'Nadia Mcknight',
        emails: [
            {
                email: 'nadiamcknight@mail.com',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'tk',
                phoneNumber: '943 511 2203',
                label: 'Mobile',
            },
            {
                country: 'tk',
                phoneNumber: '817 578 2993',
                label: 'Work',
            },
        ],
        title: 'Legal Assistant',
        company: 'Pearlesex',
        birthday: '1973-10-06T12:00:00.000Z',
        address: '448 Berriman Street, Reinerton, Washington, PO6704',
        notes: '<p>Esse sint lorem exercitation velit tempor tempor voluptate nulla proident excepteur magna tempor consectetur aliquip qui nisi mollit cupidatat est adipisicing ipsum sint et excepteur sit labore velit dolore labore.</p><p>Duis nisi adipisicing lorem do excepteur magna consequat labore magna ut consectetur eu enim occaecat id nulla laboris minim officia est id nisi mollit ullamco irure ut dolore esse aliqua.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '77a4383b-b5a5-4943-bc46-04c3431d1566',
        avatar: 'images/avatars/male-19.jpg',
        background: 'images/cards/22-640x480.jpg',
        name: 'Best Blackburn',
        emails: [
            {
                email: 'bestblackburn@mail.name',
                label: 'Personal',
            },
            {
                email: 'blackburn.best@beadzza.me',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'gl',
                phoneNumber: '814 498 3701',
                label: 'Mobile',
            },
        ],
        title: 'Hotel Manager',
        company: 'Beadzza',
        birthday: '1987-06-07T12:00:00.000Z',
        address: '578 Tampa Court, Wescosville, Ohio, PO4108',
        notes: '<p>Lorem do deserunt nulla nostrud incididunt et laboris labore eu nisi ut ullamco veniam deserunt do non labore commodo amet aliquip exercitation ea occaecat amet non eiusmod ut minim fugiat.</p><p>Esse eu ex irure pariatur qui cillum labore nulla quis officia consequat commodo consequat fugiat culpa nostrud labore eu adipisicing magna irure aliquip est amet irure eiusmod esse reprehenderit mollit.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: '8bb0f597-673a-47ca-8c77-2f83219cb9af',
        avatar: null,
        background: null,
        name: 'Duncan Carver',
        emails: [
            {
                email: 'duncancarver@mail.info',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'jm',
                phoneNumber: '968 547 2111',
                label: 'Mobile',
            },
            {
                country: 'jm',
                phoneNumber: '968 433 3120',
                label: 'Work',
            },
            {
                country: 'jm',
                phoneNumber: '905 425 2777',
                label: 'Home',
            },
        ],
        title: 'Historiographer',
        company: 'Hotcakes',
        birthday: '1980-09-15T12:00:00.000Z',
        address: '931 Bristol Street, Why, South Carolina, PO9700',
        notes: '<p>Dolore laboris aute officia reprehenderit cupidatat aliquip duis labore aliquip officia est nostrud nisi voluptate eiusmod ad aute et ea cillum aliqua elit ipsum officia cillum laborum minim labore sit.</p><p>Exercitation labore ut pariatur occaecat ullamco non occaecat aliqua amet nostrud aliquip ipsum ad do ullamco enim laborum commodo minim elit ut quis laboris elit laborum proident sunt ullamco sit.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'c318e31f-1d74-49c5-8dae-2bc5805e2fdb',
        avatar: 'images/avatars/male-01.jpg',
        background: 'images/cards/23-640x480.jpg',
        name: 'Martin Richards',
        emails: [
            {
                email: 'martinrichards@mail.biz',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'mg',
                phoneNumber: '902 500 2668',
                label: 'Mobile',
            },
            {
                country: 'mg',
                phoneNumber: '947 559 2919',
                label: 'Work',
            },
            {
                country: 'mg',
                phoneNumber: '934 434 3768',
                label: 'Home',
            },
        ],
        title: 'Dental Laboratory Worker',
        company: 'Overfork',
        birthday: '1977-04-12T12:00:00.000Z',
        address: '268 Hutchinson Court, Drytown, Florida, PO3041',
        notes: '<p>Eu ipsum nisi eu lorem cupidatat mollit exercitation elit ea culpa enim qui culpa ad aliqua exercitation tempor nulla excepteur fugiat ipsum quis amet occaecat adipisicing ullamco duis dolore occaecat.</p><p>Non eu et elit ea labore lorem adipisicing voluptate incididunt ut officia aute minim incididunt lorem qui adipisicing mollit magna nisi consectetur cillum sit exercitation eiusmod qui eu nisi sunt.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: '0a8bc517-631a-4a93-aacc-000fa2e8294c',
        avatar: 'images/avatars/female-20.jpg',
        background: 'images/cards/24-640x480.jpg',
        name: 'Candice Munoz',
        emails: [
            {
                email: 'candicemunoz@mail.co.uk',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'fm',
                phoneNumber: '838 562 2769',
                label: 'Mobile',
            },
        ],
        title: 'Legal Assistant',
        company: 'Eclipto',
        birthday: '1976-09-09T12:00:00.000Z',
        address: '946 Remsen Street, Caroline, New Mexico, PO3247',
        notes: '<p>Amet dolore elit irure in commodo in et eu eu nulla labore elit sunt et nisi quis officia nostrud et mollit dolor aute fugiat sunt reprehenderit quis sint minim ipsum.</p><p>Laboris ut sunt nisi aute incididunt reprehenderit mollit culpa velit exercitation reprehenderit irure id sunt officia magna est ea labore consectetur incididunt cillum qui tempor ea ullamco quis pariatur aliquip.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'a4c9945a-757b-40b0-8942-d20e0543cabd',
        avatar: 'images/avatars/female-01.jpg',
        background: 'images/cards/25-640x480.jpg',
        name: 'Vickie Mosley',
        emails: [
            {
                email: 'vickiemosley@mail.net',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'tr',
                phoneNumber: '939 555 3054',
                label: 'Mobile',
            },
            {
                country: 'tr',
                phoneNumber: '852 486 2053',
                label: 'Work',
            },
        ],
        title: 'Bindery Machine Operator',
        company: 'Strozen',
        birthday: '1989-06-21T12:00:00.000Z',
        address: '397 Vandalia Avenue, Rockingham, Michigan, PO8089',
        notes: '<p>Velit sunt sunt commodo ex amet laboris voluptate eu lorem aliqua minim occaecat cupidatat aliqua ipsum nisi velit id reprehenderit exercitation velit fugiat minim nisi deserunt voluptate anim cillum commodo.</p><p>Cillum velit nostrud cupidatat ex sit culpa deserunt cillum cupidatat cillum aute cupidatat exercitation ullamco sunt incididunt non magna sint lorem et incididunt laborum culpa qui sint sunt duis fugiat.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: 'b8258ccf-48b5-46a2-9c95-e0bd7580c645',
        avatar: 'images/avatars/female-02.jpg',
        background: 'images/cards/26-640x480.jpg',
        name: 'Tina Harris',
        emails: [
            {
                email: 'tinaharris@mail.ca',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'gp',
                phoneNumber: '933 464 2431',
                label: 'Mobile',
            },
            {
                country: 'gp',
                phoneNumber: '894 535 3609',
                label: 'Work',
            },
        ],
        title: 'Short Story Writer',
        company: 'Gallaxia',
        birthday: '1976-09-10T12:00:00.000Z',
        address: '821 Beverly Road, Tyro, Colorado, PO4248',
        notes: '<p>Incididunt non est consequat qui sit sunt aliquip sit quis minim laboris ullamco est culpa velit culpa cupidatat veniam incididunt non quis elit reprehenderit et officia cillum magna aliqua occaecat.</p><p>Cupidatat amet incididunt id pariatur minim veniam id dolor nisi labore cillum ea officia cupidatat do culpa aliqua consequat deserunt aliquip sit ea excepteur eiusmod labore tempor reprehenderit commodo exercitation.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'f004ea79-98fc-436c-9ba5-6cfe32fe583d',
        avatar: 'images/avatars/male-02.jpg',
        background: 'images/cards/27-640x480.jpg',
        name: 'Holt Manning',
        emails: [
            {
                email: 'holtmanning@mail.org',
                label: 'Personal',
            },
            {
                email: 'manning.holt@idetica.io',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'nz',
                phoneNumber: '822 531 2600',
                label: 'Mobile',
            },
            {
                country: 'nz',
                phoneNumber: '922 549 2094',
                label: 'Work',
            },
        ],
        title: 'Fundraising Director',
        company: 'Idetica',
        birthday: '1973-11-08T12:00:00.000Z',
        address: '364 Porter Avenue, Delshire, Missouri, PO8911',
        notes: '<p>Velit fugiat minim sit nisi esse laboris ad velit proident non et cillum labore sint excepteur nisi eu amet voluptate duis duis id enim ea anim adipisicing consectetur id consectetur.</p><p>Ex eiusmod id magna in non lorem sunt sunt officia do adipisicing officia mollit occaecat sunt laborum aliquip adipisicing ullamco in sit proident et quis incididunt pariatur fugiat mollit anim.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: '8b69fe2d-d7cc-4a3d-983d-559173e37d37',
        avatar: 'images/avatars/female-03.jpg',
        background: 'images/cards/28-640x480.jpg',
        name: 'Misty Ramsey',
        emails: [
            {
                email: 'mistyramsey@mail.us',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'kp',
                phoneNumber: '990 457 2106',
                label: 'Mobile',
            },
            {
                country: 'kp',
                phoneNumber: '918 550 2946',
                label: 'Work',
            },
        ],
        company: 'Grupoli',
        birthday: '1969-08-10T12:00:00.000Z',
        address: '101 Sackett Street, Naomi, Tennessee, PO6335',
        notes: '<p>Ut cupidatat sint minim consectetur cupidatat aute ut anim consequat fugiat laboris quis sint sit nulla irure nulla officia eiusmod consequat ex quis ad ex ullamco et ut labore tempor.</p><p>Deserunt minim dolore voluptate aute aliqua est elit mollit ut ut consequat in esse est do ex officia nostrud aute id fugiat reprehenderit quis cillum fugiat id fugiat minim tempor.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: 'cdcc62e4-1520-4ccc-803d-52868c7e01ba',
        avatar: 'images/avatars/female-04.jpg',
        background: 'images/cards/29-640x480.jpg',
        name: 'Dee Alvarado',
        emails: [
            {
                email: 'deealvarado@mail.tv',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'nu',
                phoneNumber: '855 445 2483',
                label: 'Mobile',
            },
            {
                country: 'nu',
                phoneNumber: '858 415 2860',
                label: 'Work',
            },
            {
                country: 'nu',
                phoneNumber: '968 587 2752',
                label: 'Home',
            },
        ],
        title: 'Dental Laboratory Worker',
        company: 'Tsunamia',
        birthday: '1996-06-17T12:00:00.000Z',
        address: '956 Pierrepont Street, Crumpler, Hawaii, PO3299',
        notes: '<p>Esse excepteur ad aliquip amet elit reprehenderit ut nostrud magna ex esse dolore magna excepteur irure esse incididunt sunt enim laborum ex mollit magna elit quis ullamco aute minim veniam.</p><p>Duis id ullamco laboris elit ea ea dolore tempor est eu esse aliqua quis quis ut laborum mollit cillum proident deserunt fugiat ipsum elit exercitation quis mollit eiusmod officia non.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'e2946946-b4b5-4fd7-bab4-62c38cdff2f1',
        avatar: 'images/avatars/female-05.jpg',
        background: 'images/cards/30-640x480.jpg',
        name: 'Samantha Jacobson',
        emails: [
            {
                email: 'samanthajacobson@mail.com',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'es',
                phoneNumber: '879 591 3327',
                label: 'Mobile',
            },
        ],
        title: 'Dental Laboratory Worker',
        company: 'Emoltra',
        birthday: '1972-02-04T12:00:00.000Z',
        address: '384 Love Lane, Dyckesville, New York, PO4115',
        notes: '<p>Consectetur eu et ea anim magna occaecat anim labore velit nulla non magna laboris duis sit adipisicing commodo laboris consequat id quis aliqua est culpa quis in ex est culpa.</p><p>Sunt qui excepteur reprehenderit nostrud voluptate eu laborum laborum id esse occaecat irure esse elit magna tempor ad est elit non labore tempor laborum deserunt voluptate cupidatat excepteur sunt sint.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: 'fdc77706-6ba2-4397-b2f8-a9a0b6495153',
        avatar: 'images/avatars/female-06.jpg',
        background: 'images/cards/31-640x480.jpg',
        name: 'Rhea Landry',
        emails: [
            {
                email: 'rhealandry@mail.name',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'jp',
                phoneNumber: '906 579 3698',
                label: 'Mobile',
            },
            {
                country: 'jp',
                phoneNumber: '841 475 2681',
                label: 'Work',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Comtent',
        birthday: '1988-05-22T12:00:00.000Z',
        address: '725 Arlington Avenue, Mathews, Wyoming, PO4562',
        notes: '<p>Eiusmod ullamco laboris tempor reprehenderit culpa non sunt ea consequat velit id ipsum commodo eiusmod exercitation laboris aliqua magna reprehenderit culpa tempor mollit pariatur consectetur amet aliqua cillum voluptate exercitation.</p><p>Qui cillum consectetur qui proident adipisicing id qui esse aute velit excepteur pariatur ea excepteur sunt velit nostrud esse mollit sint ex irure sunt aliquip velit consequat minim do officia.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '12148fa2-e0a4-49fb-b3c5-daeecdb5180a',
        avatar: 'images/avatars/female-07.jpg',
        background: 'images/cards/32-640x480.jpg',
        name: 'Olga Rhodes',
        emails: [
            {
                email: 'olgarhodes@mail.me',
                label: 'Personal',
            },
            {
                email: 'rhodes.olga@moreganic.info',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'tl',
                phoneNumber: '971 514 3366',
                label: 'Mobile',
            },
            {
                country: 'tl',
                phoneNumber: '807 480 2033',
                label: 'Work',
            },
            {
                country: 'tl',
                phoneNumber: '810 528 3783',
                label: 'Home',
            },
        ],
        title: 'Pastry Baker',
        company: 'Moreganic',
        birthday: '1971-08-13T12:00:00.000Z',
        address: '253 Beard Street, Staples, Massachusetts, PO8089',
        notes: '<p>Proident est est et in commodo incididunt anim fugiat laboris pariatur eu enim dolor eiusmod dolor voluptate officia eiusmod excepteur culpa aute do do anim pariatur irure incididunt incididunt est.</p><p>Sint duis mollit dolor laborum ex non esse consequat anim et qui est nostrud incididunt fugiat anim veniam sunt cupidatat ut voluptate commodo non ex tempor ullamco magna culpa culpa.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: '07dd64eb-8b8f-4765-a16c-8db083c45096',
        avatar: 'images/avatars/female-08.jpg',
        background: 'images/cards/33-640x480.jpg',
        name: 'Lorraine Pennington',
        emails: [
            {
                email: 'lorrainepennington@mail.biz',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'fm',
                phoneNumber: '932 404 3308',
                label: 'Mobile',
            },
            {
                country: 'fm',
                phoneNumber: '979 550 3200',
                label: 'Work',
            },
            {
                country: 'fm',
                phoneNumber: '868 557 3568',
                label: 'Home',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Marvane',
        birthday: '1967-06-10T12:00:00.000Z',
        address: '962 Whitney Avenue, Sussex, North Dakota, PO5796',
        notes: '<p>Nulla nisi officia quis aliquip voluptate mollit ut anim eu et quis tempor incididunt consectetur exercitation cupidatat in nisi exercitation est culpa nostrud sit elit sit sunt do ipsum eu.</p><p>Enim voluptate ad ullamco tempor voluptate culpa et ut ullamco eu consequat est esse excepteur est nostrud velit enim culpa dolore non quis occaecat eiusmod velit ex mollit tempor labore.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '81fdc48c-5572-4123-8a73-71b7892120de',
        avatar: 'images/avatars/female-09.jpg',
        background: 'images/cards/34-640x480.jpg',
        name: 'Earlene Rosales',
        emails: [
            {
                email: 'earlenerosales@mail.co.uk',
                label: 'Personal',
            },
            {
                email: 'rosales.earlene@softmicro.net',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'ki',
                phoneNumber: '927 589 3619',
                label: 'Mobile',
            },
        ],
        title: 'Historiographer',
        company: 'Softmicro',
        birthday: '1960-11-13T12:00:00.000Z',
        address: '981 Kingston Avenue, Topaz, Connecticut, PO6866',
        notes: '<p>Adipisicing fugiat magna eiusmod consectetur id commodo incididunt ullamco ut sint minim nulla in do aute in sit pariatur irure dolor magna pariatur ad officia excepteur duis ullamco dolor sunt.</p><p>Dolor laborum proident voluptate eu esse lorem adipisicing enim consectetur veniam nisi pariatur aliquip sit laborum sunt adipisicing anim labore eiusmod nostrud irure irure nisi ipsum dolor aliquip ex exercitation.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: 'f8bbf6be-d49a-41a3-bb80-3d51df84c12b',
        avatar: 'images/avatars/female-10.jpg',
        background: 'images/cards/35-640x480.jpg',
        name: 'Marcia Hatfield',
        emails: [
            {
                email: 'marciahatfield@mail.ca',
                label: 'Personal',
            },
            {
                email: 'hatfield.marcia@datagen.org',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'no',
                phoneNumber: '883 432 3718',
                label: 'Mobile',
            },
            {
                country: 'no',
                phoneNumber: '934 516 2135',
                label: 'Work',
            },
            {
                country: 'no',
                phoneNumber: '923 596 3843',
                label: 'Home',
            },
        ],
        title: 'Track Service Worker',
        company: 'Datagen',
        birthday: '1980-02-26T12:00:00.000Z',
        address: '802 Preston Court, Waikele, Pennsylvania, PO7421',
        notes: '<p>Aliqua sint aute in cillum deserunt enim fugiat tempor est pariatur irure commodo commodo deserunt eu nulla laboris enim occaecat incididunt voluptate enim est reprehenderit qui anim veniam sint adipisicing.</p><p>Commodo veniam occaecat ex et laborum minim fugiat sunt commodo velit dolor labore excepteur fugiat ipsum eiusmod in esse ex nulla deserunt minim consectetur in est sunt eu commodo fugiat.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: 'cd482941-3eaf-4560-ac37-56a9296025df',
        avatar: 'images/avatars/female-11.jpg',
        background: 'images/cards/36-640x480.jpg',
        name: 'Liliana Ayala',
        emails: [
            {
                email: 'lilianaayala@mail.io',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bd',
                phoneNumber: '936 590 2412',
                label: 'Mobile',
            },
        ],
        title: 'Insurance Analyst',
        company: 'Pharmex',
        birthday: '1988-04-27T12:00:00.000Z',
        address: '935 Guider Avenue, Kipp, Wisconsin, PO5282',
        notes: '<p>Magna et culpa cillum sint labore consequat aute aliqua amet ea consequat ut ullamco nisi commodo lorem enim amet dolor sit nisi dolor do sit lorem cillum esse reprehenderit ut.</p><p>Quis veniam anim nulla adipisicing veniam fugiat elit duis pariatur anim irure adipisicing elit labore eu aute exercitation qui exercitation commodo exercitation ipsum tempor non et ex eu aute proident.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: '22f18d47-ff8d-440e-888d-a1747c093052',
        avatar: 'images/avatars/female-12.jpg',
        background: 'images/cards/14-640x480.jpg',
        name: 'Alice Harding',
        emails: [
            {
                email: 'aliceharding@mail.us',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'sx',
                phoneNumber: '881 472 3113',
                label: 'Mobile',
            },
            {
                country: 'sx',
                phoneNumber: '974 548 3124',
                label: 'Work',
            },
            {
                country: 'sx',
                phoneNumber: '800 518 3615',
                label: 'Home',
            },
        ],
        title: 'Track Service Worker',
        company: 'Futurity',
        birthday: '1985-09-17T12:00:00.000Z',
        address: '387 Holt Court, Thomasville, Alaska, PO2867',
        notes: '<p>Adipisicing exercitation dolor nisi ipsum nostrud anim dolore sint veniam consequat lorem sit ex commodo nostrud occaecat elit magna magna commodo incididunt laborum ad irure pariatur et sit ullamco adipisicing.</p><p>Ullamco in dolore amet est quis consectetur fugiat non nisi incididunt id laborum adipisicing dolor proident velit ut quis aliquip dolore id anim sit adipisicing nisi incididunt enim amet pariatur.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: 'a9a9f382-e4c3-42fb-9fe9-65aa534732b5',
        avatar: 'images/avatars/female-13.jpg',
        background: 'images/cards/15-640x480.jpg',
        name: 'Francisca Perkins',
        emails: [
            {
                email: 'franciscaperkins@mail.tv',
                label: 'Personal',
            },
            {
                email: 'perkins.francisca@overplex.com',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'au',
                phoneNumber: '830 430 3437',
                label: 'Mobile',
            },
            {
                country: 'au',
                phoneNumber: '868 538 2886',
                label: 'Work',
            },
        ],
        title: 'Dental Laboratory Worker',
        company: 'Overplex',
        birthday: '1966-08-14T12:00:00.000Z',
        address: '733 Delmonico Place, Belvoir, Virginia, PO7102',
        notes: '<p>Voluptate nisi adipisicing ex magna mollit non cillum dolor in magna duis exercitation irure elit duis eiusmod deserunt lorem nulla sunt laboris quis voluptate ullamco labore adipisicing quis minim ipsum.</p><p>Id ut esse elit proident mollit nulla exercitation magna voluptate sit eiusmod labore velit commodo exercitation dolore anim est eiusmod occaecat et consequat eiusmod culpa ipsum deserunt lorem non incididunt.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: '0222b24b-c288-48d1-b356-0f087fa172f8',
        avatar: null,
        background: null,
        name: 'Warren Gates',
        emails: [
            {
                email: 'warrengates@mail.name',
                label: 'Personal',
            },
            {
                email: 'gates.warren@qualitex.me',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'gt',
                phoneNumber: '847 513 2248',
                label: 'Mobile',
            },
            {
                country: 'gt',
                phoneNumber: '866 591 3665',
                label: 'Work',
            },
            {
                country: 'gt',
                phoneNumber: '877 539 3840',
                label: 'Home',
            },
        ],
        title: 'Banker Mason',
        company: 'Qualitex',
        birthday: '1977-02-23T12:00:00.000Z',
        address: '713 Fane Court, Lemoyne, Kentucky, PO3601',
        notes: '<p>Sint tempor consectetur ullamco ullamco consequat exercitation ea occaecat eiusmod cupidatat anim pariatur nisi pariatur excepteur ut labore anim excepteur sit eu consequat do enim pariatur et dolore in irure.</p><p>Commodo ut non minim sunt nisi tempor culpa duis anim ipsum qui irure lorem est voluptate voluptate officia occaecat lorem labore elit officia laboris mollit et eiusmod esse laborum nisi.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '0630f1ca-cdb9-405d-b134-68f733334089',
        avatar: 'images/avatars/female-14.jpg',
        background: 'images/cards/16-640x480.jpg',
        name: 'Maryann Mcintyre',
        emails: [
            {
                email: 'maryannmcintyre@mail.info',
                label: 'Personal',
            },
            {
                email: 'mcintyre.maryann@aquafire.biz',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'bf',
                phoneNumber: '861 419 2752',
                label: 'Mobile',
            },
            {
                country: 'bf',
                phoneNumber: '935 553 3031',
                label: 'Work',
            },
        ],
        title: 'Fundraising Director',
        company: 'Aquafire',
        birthday: '1963-04-07T12:00:00.000Z',
        address: '698 Brooklyn Avenue, Dixonville, Utah, PO2712',
        notes: '<p>Pariatur velit ea ad quis elit pariatur consectetur eiusmod veniam non incididunt ex ex et nulla voluptate fugiat esse sit dolore voluptate in dolor nulla laborum irure consequat sit pariatur.</p><p>Dolore ex officia incididunt pariatur ea amet sunt enim aute labore cupidatat laboris eiusmod enim lorem labore nostrud ea consectetur et eu sunt exercitation dolore consequat fugiat anim in exercitation.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: '999c24f3-7bb8-4a01-85ca-2fca7863c57e',
        avatar: 'images/avatars/female-15.jpg',
        background: 'images/cards/17-640x480.jpg',
        name: 'Sharon Marshall',
        emails: [
            {
                email: 'sharonmarshall@mail.co.uk',
                label: 'Personal',
            },
            {
                email: 'marshall.sharon@utara.net',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'fo',
                phoneNumber: '947 441 2999',
                label: 'Mobile',
            },
            {
                country: 'fo',
                phoneNumber: '984 441 2615',
                label: 'Work',
            },
            {
                country: 'fo',
                phoneNumber: '824 541 2714',
                label: 'Home',
            },
        ],
        title: 'Legal Assistant',
        company: 'Utara',
        birthday: '1960-01-26T12:00:00.000Z',
        address: '923 Ivan Court, Hatteras, Idaho, PO7573',
        notes: '<p>Est duis sint ullamco nulla do tempor do dolore laboris in sint ad duis est eu consequat nisi esse irure tempor sunt pariatur qui mollit ipsum quis esse ex ipsum.</p><p>Dolore anim irure quis ipsum adipisicing sint et incididunt aute nisi minim aliquip consectetur duis tempor laborum nostrud exercitation do mollit irure anim lorem non excepteur commodo laborum dolore dolor.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: '7e8e1f1e-d19f-45c7-86bd-6fef599dae71',
        avatar: 'images/avatars/female-16.jpg',
        background: 'images/cards/18-640x480.jpg',
        name: 'Margo Witt',
        emails: [
            {
                email: 'margowitt@mail.ca',
                label: 'Personal',
            },
            {
                email: 'witt.margo@norsul.org',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'ao',
                phoneNumber: '992 596 3391',
                label: 'Mobile',
            },
            {
                country: 'ao',
                phoneNumber: '950 489 2505',
                label: 'Work',
            },
            {
                country: 'ao',
                phoneNumber: '891 540 2231',
                label: 'Home',
            },
        ],
        title: 'Television News Producer',
        company: 'Norsul',
        birthday: '1975-08-31T12:00:00.000Z',
        address: '539 Rockaway Avenue, Whitmer, Guam, PO4871',
        notes: '<p>Sunt quis officia elit laborum excepteur consequat amet cillum labore deserunt cillum cillum labore exercitation minim laboris anim incididunt voluptate minim duis enim eu duis veniam labore nisi culpa duis.</p><p>Pariatur irure sunt et commodo reprehenderit consectetur duis et ullamco fugiat occaecat culpa enim incididunt officia minim aliqua sit amet do dolore pariatur fugiat et adipisicing labore dolor id dolore.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: 'bedcb6a2-da83-4631-866a-77d10d239477',
        avatar: 'images/avatars/male-04.jpg',
        background: 'images/cards/19-640x480.jpg',
        name: 'Alvarado Turner',
        emails: [
            {
                email: 'alvaradoturner@mail.io',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'lv',
                phoneNumber: '961 537 3956',
                label: 'Mobile',
            },
        ],
        title: 'Fundraising Director',
        company: 'Geologix',
        birthday: '1985-12-08T12:00:00.000Z',
        address: '233 Willmohr Street, Cressey, Iowa, PO1962',
        notes: '<p>In amet voluptate ad eiusmod cupidatat nulla sunt eu amet occaecat qui cillum occaecat tempor minim nostrud ullamco amet elit aliquip est nisi officia lorem occaecat ea lorem officia veniam.</p><p>Nulla tempor id excepteur irure do do veniam eiusmod esse ipsum sint dolore commodo enim officia nulla nulla proident in dolor et aliquip sit nulla sit proident duis aute deserunt.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '66f9de1b-f842-4d4c-bb59-f97e91db0462',
        avatar: 'images/avatars/male-05.jpg',
        background: 'images/cards/20-640x480.jpg',
        name: 'Maldonado Rodriquez',
        emails: [
            {
                email: 'maldonadorodriquez@mail.us',
                label: 'Personal',
            },
            {
                email: 'rodriquez.maldonado@zentility.tv',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'et',
                phoneNumber: '811 502 3398',
                label: 'Mobile',
            },
            {
                country: 'et',
                phoneNumber: '877 402 2443',
                label: 'Work',
            },
            {
                country: 'et',
                phoneNumber: '949 536 3451',
                label: 'Home',
            },
        ],
        title: 'Dental Laboratory Worker',
        company: 'Zentility',
        birthday: '1993-06-01T12:00:00.000Z',
        address: '916 Cobek Court, Morningside, South Dakota, PO2019',
        notes: '<p>Laboris consequat labore nisi aute voluptate minim amet nulla elit tempor dolor nulla do et consequat esse dolore fugiat laboris deserunt velit minim laboris voluptate enim ut non laboris nisi.</p><p>Magna pariatur voluptate veniam nostrud irure magna pariatur ut quis reprehenderit voluptate aute duis sunt laboris consequat lorem eu pariatur nulla incididunt quis lorem consectetur ex lorem commodo magna dolore.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '9cb0ea57-3461-4182-979b-593b0c1ec6c3',
        avatar: 'images/avatars/male-06.jpg',
        background: 'images/cards/21-640x480.jpg',
        name: 'Tran Duke',
        emails: [
            {
                email: 'tranduke@mail.com',
                label: 'Personal',
            },
            {
                email: 'duke.tran@splinx.name',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'si',
                phoneNumber: '837 503 2254',
                label: 'Mobile',
            },
            {
                country: 'si',
                phoneNumber: '893 405 3190',
                label: 'Work',
            },
            {
                country: 'si',
                phoneNumber: '931 402 3874',
                label: 'Home',
            },
        ],
        title: 'Legal Assistant',
        company: 'Splinx',
        birthday: '1976-04-27T12:00:00.000Z',
        address: '405 Canarsie Road, Richville, Virgin Islands, PO2744',
        notes: '<p>Occaecat do excepteur non ipsum labore consequat id eu sunt minim aliquip elit occaecat velit ut aute cupidatat irure ex eiusmod fugiat ea ea cupidatat nulla dolor labore consectetur amet.</p><p>Mollit enim dolore deserunt tempor aliqua velit nostrud nostrud id consectetur lorem in enim excepteur nisi laborum ex commodo sint ea et culpa lorem esse culpa ad officia do amet.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: '2fb89a90-5622-4b5b-8df3-d49b85905392',
        avatar: null,
        background: null,
        name: 'Estela Lyons',
        emails: [
            {
                email: 'estelalyons@mail.me',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'vg',
                phoneNumber: '864 459 3205',
                label: 'Mobile',
            },
            {
                country: 'vg',
                phoneNumber: '886 524 2880',
                label: 'Work',
            },
            {
                country: 'vg',
                phoneNumber: '815 484 3420',
                label: 'Home',
            },
        ],
        title: 'Animal Sitter',
        company: 'Gonkle',
        birthday: '1968-03-11T12:00:00.000Z',
        address: '540 Metrotech Courtr, Garfield, American Samoa, PO2290',
        notes: '<p>Ullamco dolore ipsum exercitation officia dolore sit consequat nisi consequat occaecat et ipsum veniam anim tempor pariatur sunt in adipisicing aliqua non dolor laborum veniam nisi dolore quis sunt incididunt.</p><p>Incididunt ullamco sunt magna reprehenderit velit dolor qui anim eiusmod nostrud commodo exercitation velit incididunt exercitation nulla ad aute eiusmod est amet exercitation est nostrud sit esse esse ad irure.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: '8141dd08-3a6e-4770-912c-59d0ed06dde6',
        avatar: null,
        background: null,
        name: 'Madeleine Fletcher',
        emails: [
            {
                email: 'madeleinefletcher@mail.info',
                label: 'Personal',
            },
            {
                email: 'fletcher.madeleine@genmom.biz',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'uy',
                phoneNumber: '898 554 3354',
                label: 'Mobile',
            },
        ],
        title: 'Fundraising Director',
        company: 'Genmom',
        birthday: '1970-07-15T12:00:00.000Z',
        address: '825 Cherry Street, Foscoe, Minnesota, PO7290',
        notes: '<p>Fugiat in exercitation nostrud labore labore irure ex magna ex aliquip veniam sit irure irure deserunt occaecat tempor cillum aliqua dolore ea tempor dolore laboris est amet quis consequat quis.</p><p>Esse officia velit consectetur ullamco ea pariatur mollit sit consectetur sint mollit commodo anim anim ea amet consectetur eiusmod aliqua excepteur elit laborum magna non fugiat nisi pariatur ut velit.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '7585015c-ada2-4f88-998d-9646865d1ad2',
        avatar: 'images/avatars/male-07.jpg',
        background: 'images/cards/22-640x480.jpg',
        name: 'Meyer Roach',
        emails: [
            {
                email: 'meyerroach@mail.co.uk',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'uz',
                phoneNumber: '891 543 2053',
                label: 'Mobile',
            },
            {
                country: 'uz',
                phoneNumber: '842 564 3671',
                label: 'Work',
            },
            {
                country: 'uz',
                phoneNumber: '992 491 3514',
                label: 'Home',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Zentime',
        birthday: '1968-10-16T12:00:00.000Z',
        address: '315 Albemarle Road, Allison, Arkansas, PO6008',
        notes: '<p>Eiusmod deserunt aliqua dolore ipsum cillum veniam minim dolore nulla aute aliqua voluptate labore sint cillum excepteur nulla nostrud do cupidatat eu adipisicing reprehenderit deserunt elit qui mollit adipisicing eu.</p><p>Proident commodo magna eu voluptate eiusmod aliqua laborum eu ea elit quis ullamco ullamco magna minim enim amet dolore sit lorem aliqua officia amet officia non magna enim cillum sit.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '32c73a6a-67f2-48a9-b2a1-b23da83187bb',
        avatar: null,
        background: null,
        name: 'Bolton Obrien',
        emails: [
            {
                email: 'boltonobrien@mail.net',
                label: 'Personal',
            },
            {
                email: 'obrien.bolton@enersol.ca',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'tn',
                phoneNumber: '860 472 2458',
                label: 'Mobile',
            },
            {
                country: 'tn',
                phoneNumber: '887 499 3580',
                label: 'Work',
            },
        ],
        title: 'Banker Mason',
        company: 'Enersol',
        birthday: '1968-09-08T12:00:00.000Z',
        address: '818 Aviation Road, Geyserville, Palau, PO9655',
        notes: '<p>Cupidatat lorem tempor commodo do eu ea dolor eiusmod do nisi occaecat fugiat labore non esse aliquip ullamco laboris adipisicing pariatur nostrud enim minim do fugiat culpa exercitation lorem duis.</p><p>Pariatur cupidatat tempor est et nostrud in amet aliquip sint nulla amet ea lorem irure sint sit ea aliquip voluptate id laboris fugiat cillum cillum dolore deserunt fugiat ad tempor.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: '114642a2-ccb7-4cb1-ad2b-5e9b6a0c1d2e',
        avatar: 'images/avatars/male-09.jpg',
        background: 'images/cards/23-640x480.jpg',
        name: 'Barber Johnson',
        emails: [
            {
                email: 'barberjohnson@mail.org',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'az',
                phoneNumber: '928 567 2521',
                label: 'Mobile',
            },
            {
                country: 'az',
                phoneNumber: '898 515 2048',
                label: 'Work',
            },
            {
                country: 'az',
                phoneNumber: '935 495 3348',
                label: 'Home',
            },
        ],
        title: 'Talent Manager',
        company: 'Zounds',
        birthday: '1967-03-02T12:00:00.000Z',
        address: '386 Vernon Avenue, Dragoon, North Carolina, PO4559',
        notes: '<p>Esse amet ex duis esse aliqua non tempor ullamco dolore et aliquip nisi pariatur qui laborum id consequat tempor sint eiusmod exercitation velit aliquip occaecat tempor nisi aute magna sint.</p><p>Deserunt veniam voluptate dolore eiusmod eu consequat dolor sit pariatur laboris anim excepteur consequat nulla officia exercitation magna sint ea excepteur qui eu officia pariatur culpa sint elit nulla officia.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '310ece7d-dbb0-45d6-9e69-14c24e50fe3d',
        avatar: 'images/avatars/male-10.jpg',
        background: 'images/cards/24-640x480.jpg',
        name: 'Cervantes Kramer',
        emails: [
            {
                email: 'cervanteskramer@mail.io',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'vg',
                phoneNumber: '998 498 2507',
                label: 'Mobile',
            },
            {
                country: 'vg',
                phoneNumber: '856 477 3445',
                label: 'Work',
            },
        ],
        title: 'Motor Winder',
        company: 'Xeronk',
        birthday: '1992-09-04T12:00:00.000Z',
        address: '238 Rochester Avenue, Lydia, Oklahoma, PO3914',
        notes: '<p>Excepteur do ullamco voluptate deserunt tempor ullamco enim non incididunt adipisicing sunt sint sit qui occaecat occaecat id laboris et duis amet reprehenderit cupidatat aliquip dolore ea eu ea nulla.</p><p>Cillum nulla deserunt laboris eu sint dolor non laboris cupidatat aute nisi amet mollit ipsum cillum excepteur consequat tempor exercitation consequat nostrud ipsum qui excepteur eiusmod nostrud laboris pariatur sint.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: 'dcc673f6-de59-4715-94ed-8f64663d449b',
        avatar: 'images/avatars/female-19.jpg',
        background: 'images/cards/25-640x480.jpg',
        name: 'Megan Suarez',
        emails: [
            {
                email: 'megansuarez@mail.us',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bb',
                phoneNumber: '875 422 2053',
                label: 'Mobile',
            },
            {
                country: 'bb',
                phoneNumber: '861 487 2597',
                label: 'Work',
            },
            {
                country: 'bb',
                phoneNumber: '873 414 3953',
                label: 'Home',
            },
        ],
        title: 'Bindery Machine Operator',
        company: 'Cemention',
        birthday: '1984-09-08T12:00:00.000Z',
        address: '112 Tillary Street, Camptown, Vermont, PO8827',
        notes: '<p>Pariatur tempor laborum deserunt commodo eiusmod adipisicing amet anim irure fugiat laboris velit do velit elit aute deserunt officia fugiat nulla ullamco est elit veniam officia sit veniam velit commodo.</p><p>Laboris duis eu adipisicing esse fugiat voluptate enim sint in voluptate lorem laboris eiusmod commodo nostrud dolor qui incididunt non fugiat culpa aliquip minim voluptate lorem sint sunt velit eiusmod.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: '3e4ca731-d39b-4ad9-b6e0-f84e67f4b74a',
        avatar: 'images/avatars/female-20.jpg',
        background: 'images/cards/26-640x480.jpg',
        name: 'Ofelia Ratliff',
        emails: [
            {
                email: 'ofeliaratliff@mail.tv',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'vu',
                phoneNumber: '978 546 3699',
                label: 'Mobile',
            },
            {
                country: 'vu',
                phoneNumber: '892 551 2229',
                label: 'Work',
            },
            {
                country: 'vu',
                phoneNumber: '949 495 3479',
                label: 'Home',
            },
        ],
        company: 'Buzzmaker',
        birthday: '1988-11-11T12:00:00.000Z',
        address: '951 Hampton Avenue, Bartonsville, Mississippi, PO4232',
        notes: '<p>Ad lorem id irure aute ipsum ex occaecat commodo dolore eu dolor exercitation anim quis officia deserunt lorem sunt officia eu sit aliquip laborum id duis aliqua quis aute magna.</p><p>Do do lorem est amet aliqua ex excepteur nisi cupidatat esse consequat ipsum in ad eiusmod proident cupidatat dolore anim ut pariatur sint do elit incididunt officia adipisicing amet eu.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
    {
        id: '2012d4a5-19e4-444d-aaff-1d8b1d853650',
        avatar: 'images/avatars/female-01.jpg',
        background: 'images/cards/27-640x480.jpg',
        name: 'Laurel Parker',
        emails: [
            {
                email: 'laurelparker@mail.com',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'lu',
                phoneNumber: '805 502 3677',
                label: 'Mobile',
            },
            {
                country: 'lu',
                phoneNumber: '925 527 2973',
                label: 'Work',
            },
            {
                country: 'lu',
                phoneNumber: '975 495 2977',
                label: 'Home',
            },
        ],
        title: 'Fundraising Director',
        company: 'Omnigog',
        birthday: '1987-05-17T12:00:00.000Z',
        address: '157 Woodhull Street, Rutherford, West Virginia, PO6646',
        notes: '<p>Duis laboris consectetur et anim eiusmod laborum aute mollit ut officia ipsum dolore eiusmod ex eu elit officia est amet aliquip ullamco veniam proident id aliquip duis qui voluptate fugiat.</p><p>Sunt aliquip nulla amet sint culpa laboris quis proident qui veniam excepteur ullamco irure non eu occaecat est enim ut velit dolore sit tempor cillum reprehenderit proident velit lorem ad.</p>',
        tags: ['2026ce08-d08f-4b4f-9506-b10cdb5b104f'],
    },
    {
        id: '012b8219-74bf-447c-af2c-66904d90a956',
        avatar: 'images/avatars/female-02.jpg',
        background: 'images/cards/28-640x480.jpg',
        name: 'Tracy Delacruz',
        emails: [
            {
                email: 'tracydelacruz@mail.name',
                label: 'Personal',
            },
            {
                email: 'delacruz.tracy@shepard.me',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'co',
                phoneNumber: '974 428 2886',
                label: 'Mobile',
            },
        ],
        title: 'Bindery Machine Operator',
        company: 'Shepard',
        birthday: '1963-08-10T12:00:00.000Z',
        address: '604 Merit Court, Wyano, New Hampshire, PO1641',
        notes: '<p>Dolor anim fugiat aliquip eiusmod lorem nisi adipisicing ea deserunt est quis non sit nulla voluptate deserunt magna eiusmod irure labore fugiat consectetur laboris velit voluptate exercitation aute magna sit.</p><p>Sunt ullamco quis qui ea ullamco quis sit ex nisi deserunt fugiat qui culpa minim proident dolor veniam lorem nulla amet do dolor proident sunt ex incididunt ipsum cillum non.</p>',
        tags: ['c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309'],
    },
    {
        id: '8b1befd2-66a7-4981-ae52-77f01b382d18',
        avatar: 'images/avatars/female-03.jpg',
        background: 'images/cards/29-640x480.jpg',
        name: 'Jeannette Stanton',
        emails: [
            {
                email: 'jeannettestanton@mail.info',
                label: 'Personal',
            },
            {
                email: 'stanton.jeannette@zentury.biz',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'dz',
                phoneNumber: '947 561 3783',
                label: 'Mobile',
            },
            {
                country: 'dz',
                phoneNumber: '917 463 3737',
                label: 'Work',
            },
            {
                country: 'dz',
                phoneNumber: '835 510 2059',
                label: 'Home',
            },
        ],
        title: 'Hotel Manager',
        company: 'Zentury',
        birthday: '1975-09-02T12:00:00.000Z',
        address: '100 Menahan Street, Snyderville, Kansas, PO1006',
        notes: '<p>Sint anim sint tempor proident irure proident exercitation dolor enim in sint non occaecat tempor mollit dolore ea labore ipsum sunt in incididunt proident excepteur id in velit et quis.</p><p>Amet mollit ut nostrud cupidatat ut culpa irure in ex occaecat aute aliqua tempor incididunt elit nulla irure aliqua ea do amet ex elit incididunt minim eu fugiat elit pariatur.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '844668c3-5e20-4fed-9e3a-7d274f696e61',
        avatar: 'images/avatars/female-04.jpg',
        background: 'images/cards/30-640x480.jpg',
        name: 'Johnnie Cleveland',
        emails: [
            {
                email: 'johnniecleveland@mail.co.uk',
                label: 'Personal',
            },
            {
                email: 'cleveland.johnnie@viasia.net',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'au',
                phoneNumber: '947 468 2942',
                label: 'Mobile',
            },
        ],
        title: 'Fundraising Director',
        company: 'Viasia',
        birthday: '1986-03-15T12:00:00.000Z',
        address: '283 Albany Avenue, Jennings, Rhode Island, PO1646',
        notes: '<p>Id est dolore nostrud consectetur ullamco aliquip dolore nisi consectetur cupidatat consectetur ut lorem exercitation laborum est culpa qui aliquip fugiat fugiat laborum minim sint sit laborum elit consectetur occaecat.</p><p>Cillum eu aliquip ex enim dolore enim ea pariatur elit voluptate in eu magna eu voluptate est cupidatat aliqua cupidatat ex eu dolor voluptate velit fugiat ipsum labore labore aliqua.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: '5a01e870-8be1-45a5-b58a-ec09c06e8f28',
        avatar: 'images/avatars/female-05.jpg',
        background: 'images/cards/31-640x480.jpg',
        name: 'Staci Hyde',
        emails: [
            {
                email: 'stacihyde@mail.ca',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'id',
                phoneNumber: '944 525 2944',
                label: 'Mobile',
            },
            {
                country: 'id',
                phoneNumber: '877 500 2506',
                label: 'Work',
            },
        ],
        title: 'Banker Mason',
        company: 'Zilla',
        birthday: '1975-04-22T12:00:00.000Z',
        address: '560 Dooley Street, Ellerslie, Louisiana, PO1005',
        notes: '<p>Pariatur esse ex laborum ex dolor laborum proident enim consectetur occaecat magna adipisicing magna dolore officia aute et dolor aliquip enim adipisicing culpa reprehenderit aliqua officia qui pariatur aliquip occaecat.</p><p>Excepteur est nisi officia eiusmod et duis mollit labore minim duis officia lorem ipsum duis deserunt cupidatat excepteur nostrud incididunt non cillum fugiat adipisicing anim consectetur nostrud aliquip labore cupidatat.</p>',
        tags: ['56ddbd47-4078-4ddd-8448-73c5e88d5f59'],
    },
    {
        id: '5ac1f193-f150-45f9-bfe4-b7b4e1a83ff9',
        avatar: 'images/avatars/female-06.jpg',
        background: 'images/cards/32-640x480.jpg',
        name: 'Angela Gallagher',
        emails: [
            {
                email: 'angelagallagher@mail.org',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'et',
                phoneNumber: '996 514 3856',
                label: 'Mobile',
            },
            {
                country: 'et',
                phoneNumber: '903 539 2049',
                label: 'Work',
            },
            {
                country: 'et',
                phoneNumber: '938 463 3685',
                label: 'Home',
            },
        ],
        title: 'Electromedical Equipment Technician',
        company: 'Zenolux',
        birthday: '1965-08-02T12:00:00.000Z',
        address: '445 Remsen Avenue, Ruckersville, Delaware, PO2712',
        notes: '<p>Pariatur do nisi labore culpa minim aliquip excepteur voluptate id id aute eu aliquip adipisicing nulla laboris consectetur dolore ullamco ut exercitation fugiat excepteur veniam ex cillum cupidatat ad adipisicing.</p><p>Dolor culpa dolor magna incididunt voluptate sunt amet dolor cillum ut nostrud nisi quis ex pariatur enim dolore sunt sunt cupidatat id non lorem magna esse amet commodo minim id.</p>',
        tags: ['cbde2486-5033-4e09-838e-e901b108cd41'],
    },
    {
        id: '995df091-d78a-4bb7-840c-ba6a7d14a1bd',
        avatar: 'images/avatars/male-11.jpg',
        background: 'images/cards/33-640x480.jpg',
        name: 'Hutchinson Levy',
        emails: [
            {
                email: 'hutchinsonlevy@mail.io',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'et',
                phoneNumber: '970 546 3452',
                label: 'Mobile',
            },
            {
                country: 'et',
                phoneNumber: '894 438 2430',
                label: 'Work',
            },
        ],
        title: 'Congressional Representative',
        company: 'Zytrek',
        birthday: '1978-03-22T12:00:00.000Z',
        address: '911 Lois Avenue, Epworth, California, PO6557',
        notes: '<p>Veniam deserunt aliquip culpa commodo et est ea cillum ea pariatur reprehenderit dolore adipisicing voluptate dolor eiusmod tempor exercitation reprehenderit nostrud labore nostrud do nulla commodo officia qui culpa ea.</p><p>Velit deserunt do ut esse tempor minim cupidatat amet qui consequat enim duis elit veniam sunt sit aliquip irure cillum irure sunt officia incididunt cupidatat commodo amet non qui anim.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: '7184be71-a28f-4f2b-8c45-15f78cf2f825',
        avatar: 'images/avatars/female-05.jpg',
        background: 'images/cards/34-640x480.jpg',
        name: 'Alissa Nelson',
        emails: [
            {
                email: 'alissanelson@mail.us',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'lu',
                phoneNumber: '893 600 2639',
                label: 'Mobile',
            },
        ],
        title: 'Bindery Machine Operator',
        company: 'Emtrak',
        birthday: '1993-10-19T12:00:00.000Z',
        address: '514 Sutter Avenue, Shindler, Puerto Rico, PO3862',
        notes: '<p>Ullamco ut aute reprehenderit velit incididunt veniam consequat ut ipsum sint laborum duis officia pariatur mollit enim nulla reprehenderit dolor aliquip labore ex aute in sunt dolor nulla reprehenderit dolor.</p><p>Ad enim ex non minim commodo culpa culpa ex est anim aute adipisicing proident ut ex et aliquip amet exercitation lorem tempor laborum quis reprehenderit veniam proident ullamco id eiusmod.</p>',
        tags: ['3eaab175-ec0d-4db7-bc3b-efc633c769be'],
    },
    {
        id: '325d508c-ca49-42bf-b0d5-c4a6b8da3d5c',
        avatar: null,
        background: null,
        name: 'Oliver Head',
        emails: [
            {
                email: 'oliverhead@mail.tv',
                label: 'Personal',
            },
        ],
        phoneNumbers: [
            {
                country: 'bn',
                phoneNumber: '977 528 3294',
                label: 'Mobile',
            },
        ],
        title: 'Meteorologist',
        company: 'Rameon',
        birthday: '1967-01-05T12:00:00.000Z',
        address: '569 Clermont Avenue, Movico, Marshall Islands, PO7293',
        notes: '<p>Duis laborum magna ipsum officia cillum ea ut commodo anim exercitation incididunt id ipsum nisi consectetur aute officia culpa anim in veniam ad officia consequat qui ullamco ea laboris ad.</p><p>Ad ea excepteur ea veniam nostrud est labore ea consectetur laboris cupidatat aute pariatur aute mollit dolor do deserunt nisi mollit fugiat qui officia ullamco est officia est ullamco consequat.</p>',
        tags: ['65930b5a-5d2a-4303-b11f-865d69e6fdb5'],
    },
    {
        id: 'c674b6e1-b846-4bba-824b-0b4df0cdec48',
        avatar: 'images/avatars/male-13.jpg',
        background: 'images/cards/35-640x480.jpg',
        name: 'Duran Barr',
        emails: [
            {
                email: 'duranbarr@mail.com',
                label: 'Personal',
            },
            {
                email: 'barr.duran@hinway.name',
                label: 'Work',
            },
        ],
        phoneNumbers: [
            {
                country: 'sr',
                phoneNumber: '857 457 2508',
                label: 'Mobile',
            },
            {
                country: 'sr',
                phoneNumber: '887 522 2146',
                label: 'Work',
            },
            {
                country: 'sr',
                phoneNumber: '947 574 3174',
                label: 'Home',
            },
        ],
        title: 'Insurance Analyst',
        company: 'Hinway',
        birthday: '1977-11-06T12:00:00.000Z',
        address: '103 Chestnut Avenue, Glenbrook, Indiana, PO2578',
        notes: '<p>Ad ipsum occaecat dolore ullamco labore ex sint est pariatur aliquip ea do esse do est dolore duis excepteur esse irure eiusmod pariatur elit nostrud laboris ad ex nostrud nostrud.</p><p>Occaecat proident magna elit ullamco ea incididunt fugiat est nulla reprehenderit in veniam esse qui minim aliqua tempor excepteur dolor et tempor occaecat in veniam esse qui exercitation laborum esse.</p>',
        tags: ['a8991c76-2fda-4bbd-a718-df13d6478847'],
    },
];
export const countries = [
    {
        id: '19430ee3-b0fe-4987-a7c8-74453ad5504d',
        iso: 'af',
        name: 'Afghanistan',
        code: '+93',
        flagImagePos: '-1px -3180px',
    },
    {
        id: '6c6b5c5c-97d5-4881-b5e1-e05b8f739ee7',
        iso: 'al',
        name: 'Albania',
        code: '+355',
        flagImagePos: '-1px -1310px',
    },
    {
        id: 'd1f3941f-075e-4777-a5fd-8b196d98cd5a',
        iso: 'dz',
        name: 'Algeria',
        code: '+213',
        flagImagePos: '-1px -681px',
    },
    {
        id: '0dc3d1b8-f7f3-4c3d-8493-0d8b5a679910',
        iso: 'as',
        name: 'American Samoa',
        code: '+1',
        flagImagePos: '-1px -2058px',
    },
    {
        id: 'e2e88578-b410-499f-aa59-9bb8da13c781',
        iso: 'ad',
        name: 'Andorra',
        code: '+376',
        flagImagePos: '-1px -766px',
    },
    {
        id: '4446885b-b391-4b84-866f-2b36603053c4',
        iso: 'ao',
        name: 'Angola',
        code: '+244',
        flagImagePos: '-1px -2636px',
    },
    {
        id: '07024099-a3db-4881-a628-24e8c0ba2508',
        iso: 'ai',
        name: 'Anguilla',
        code: '+1',
        flagImagePos: '-1px -2687px',
    },
    {
        id: '26be08bc-d87a-4134-9fb0-73b6a5b47cea',
        iso: 'ag',
        name: 'Antigua & Barbuda',
        code: '+1',
        flagImagePos: '-1px -1140px',
    },
    {
        id: '53c77399-494e-49df-9e3a-587b536c033e',
        iso: 'ar',
        name: 'Argentina',
        code: '+54',
        flagImagePos: '-1px -3282px',
    },
    {
        id: '9f5753c4-e9e4-4975-86b4-9eb9f4f484de',
        iso: 'am',
        name: 'Armenia',
        code: '+374',
        flagImagePos: '-1px -205px',
    },
    {
        id: 'f1bbb833-5c47-4e17-b8c3-1d492107dc86',
        iso: 'aw',
        name: 'Aruba',
        code: '+297',
        flagImagePos: '-1px -1021px',
    },
    {
        id: 'dc7e3322-8bd5-4c49-932d-a8e50bd1f9ad',
        iso: 'ac',
        name: 'Ascension Island',
        code: '+247',
        flagImagePos: '-1px -86px',
    },
    {
        id: '4505ba35-afa5-47ef-a6c7-9b57f1dcd187',
        iso: 'au',
        name: 'Australia',
        code: '+61',
        flagImagePos: '-1px -2279px',
    },
    {
        id: '57b3cd1f-d5d6-403b-8137-fbeeacaf136a',
        iso: 'at',
        name: 'Austria',
        code: '+43',
        flagImagePos: '-1px -1735px',
    },
    {
        id: '11cbde08-3c33-422c-bf4b-85561595ffb5',
        iso: 'az',
        name: 'Azerbaijan',
        code: '+994',
        flagImagePos: '-1px -1599px',
    },
    {
        id: '48c1e060-e685-4e91-8de8-725f42576e6c',
        iso: 'bs',
        name: 'Bahamas',
        code: '+1',
        flagImagePos: '-1px -460px',
    },
    {
        id: 'ee23ffb8-9540-4630-948e-ceba52fa54ce',
        iso: 'bh',
        name: 'Bahrain',
        code: '+973',
        flagImagePos: '-1px -1956px',
    },
    {
        id: 'b5f37cb6-7870-4ed9-8f92-3864bd870062',
        iso: 'bd',
        name: 'Bangladesh',
        code: '+880',
        flagImagePos: '-1px -2364px',
    },
    {
        id: '92de9080-f709-493e-a9fa-d23b3d4093d4',
        iso: 'bb',
        name: 'Barbados',
        code: '+1',
        flagImagePos: '-1px -2075px',
    },
    {
        id: 'a2f4ff04-86b8-4bc0-952f-686bfe99c07f',
        iso: 'by',
        name: 'Belarus',
        code: '+375',
        flagImagePos: '-1px -1412px',
    },
    {
        id: '2025b6b3-1287-4b4c-8b13-36deb44e5751',
        iso: 'be',
        name: 'Belgium',
        code: '+32',
        flagImagePos: '-1px -1px',
    },
    {
        id: '70d82950-3eca-496f-866d-d99c136260e5',
        iso: 'bz',
        name: 'Belize',
        code: '+501',
        flagImagePos: '-1px -613px',
    },
    {
        id: 'dc0bedf5-e197-46b4-af21-c2e495b15768',
        iso: 'bj',
        name: 'Benin',
        code: '+229',
        flagImagePos: '-1px -1684px',
    },
    {
        id: 'aeee4f9d-99a1-4c6b-826c-f3c0ff707dce',
        iso: 'bm',
        name: 'Bermuda',
        code: '+1',
        flagImagePos: '-1px -2585px',
    },
    {
        id: '73b80fa7-50d0-4fd5-8d26-24baade525a2',
        iso: 'bt',
        name: 'Bhutan',
        code: '+975',
        flagImagePos: '-1px -2483px',
    },
    {
        id: '571bf396-810b-4fc4-9ffc-c9e4db9d3bef',
        iso: 'bo',
        name: 'Bolivia',
        code: '+591',
        flagImagePos: '-1px -2177px',
    },
    {
        id: 'cbfbf28b-b79b-4b7d-a2e9-37a2000aa15b',
        iso: 'ba',
        name: 'Bosnia & Herzegovina',
        code: '+387',
        flagImagePos: '-1px -2092px',
    },
    {
        id: 'f929da82-915c-4ac8-ba13-aa1b44174c71',
        iso: 'bw',
        name: 'Botswana',
        code: '+267',
        flagImagePos: '-1px -3724px',
    },
    {
        id: '2dea0689-0548-400c-a58f-ebcd6373cd07',
        iso: 'br',
        name: 'Brazil',
        code: '+55',
        flagImagePos: '-1px -1004px',
    },
    {
        id: 'd2c2c16f-15f8-467b-8c42-a02babe5362b',
        iso: 'io',
        name: 'British Indian Ocean Territory',
        code: '+246',
        flagImagePos: '-1px -86px',
    },
    {
        id: '1d90db23-ca7c-4d23-a995-9b2a8021f4ad',
        iso: 'vg',
        name: 'British Virgin Islands',
        code: '+1',
        flagImagePos: '-1px -1854px',
    },
    {
        id: 'f16aebb2-cdae-4af2-aba5-f66f34d6ac3a',
        iso: 'bn',
        name: 'Brunei',
        code: '+673',
        flagImagePos: '-1px -2228px',
    },
    {
        id: '499d6ee6-8f8b-4a5b-bb92-9cce9d1c6546',
        iso: 'bg',
        name: 'Bulgaria',
        code: '+359',
        flagImagePos: '-1px -3537px',
    },
    {
        id: '67e2986b-98d0-44c3-b08f-6cbba8b14ff8',
        iso: 'bf',
        name: 'Burkina Faso',
        code: '+226',
        flagImagePos: '-1px -953px',
    },
    {
        id: 'fea611f2-4aa3-427f-86e1-657e8aef24a8',
        iso: 'bi',
        name: 'Burundi',
        code: '+257',
        flagImagePos: '-1px -2551px',
    },
    {
        id: '3b959360-3d04-4018-afdf-a392afa1881d',
        iso: 'kh',
        name: 'Cambodia',
        code: '+855',
        flagImagePos: '-1px -290px',
    },
    {
        id: '9336ba3b-01be-4b84-82b5-f02395856ac5',
        iso: 'cm',
        name: 'Cameroon',
        code: '+237',
        flagImagePos: '-1px -2806px',
    },
    {
        id: '36a159b0-f33e-4481-85b0-751bdd9ea79d',
        iso: 'ca',
        name: 'Canada',
        code: '+1',
        flagImagePos: '-1px -1803px',
    },
    {
        id: 'a3038010-382e-436e-b61d-e4b923aa1cb3',
        iso: 'cv',
        name: 'Cape Verde',
        code: '+238',
        flagImagePos: '-1px -3639px',
    },
    {
        id: 'dd898165-12a9-4c90-a3e4-012149c0feac',
        iso: 'bq',
        name: 'Caribbean Netherlands',
        code: '+599',
        flagImagePos: '-1px -3741px',
    },
    {
        id: 'a1f30091-26da-481a-a84f-2638b2d7c14d',
        iso: 'ky',
        name: 'Cayman Islands',
        code: '+1',
        flagImagePos: '-1px -375px',
    },
    {
        id: '469b4a79-8a1a-4428-b7bd-4665202b7292',
        iso: 'cf',
        name: 'Central African Republic',
        code: '+236',
        flagImagePos: '-1px -2466px',
    },
    {
        id: 'a9c2fa4b-c22a-41bd-9735-b4adeadab7f7',
        iso: 'td',
        name: 'Chad',
        code: '+235',
        flagImagePos: '-1px -1055px',
    },
    {
        id: 'f0825f0d-e086-49e0-846e-9e4784bf872c',
        iso: 'cl',
        name: 'Chile',
        code: '+56',
        flagImagePos: '-1px -1752px',
    },
    {
        id: '89d3f07d-446e-459d-b168-595af96d708f',
        iso: 'cn',
        name: 'China',
        code: '+86',
        flagImagePos: '-1px -1072px',
    },
    {
        id: '903801ce-2f83-4df8-a380-9dc6df6c35cf',
        iso: 'co',
        name: 'Colombia',
        code: '+57',
        flagImagePos: '-1px -409px',
    },
    {
        id: '55d7d2be-8273-4770-844c-1ef87524cd27',
        iso: 'km',
        name: 'Comoros',
        code: '+269',
        flagImagePos: '-1px -1871px',
    },
    {
        id: 'a5b00b2f-01de-4c0d-914f-fe05c92c8f43',
        iso: 'cg',
        name: 'Congo - Brazzaville',
        code: '+242',
        flagImagePos: '-1px -2398px',
    },
    {
        id: '58e07572-21b9-4630-a17c-a51c0ade4b8a',
        iso: 'cd',
        name: 'Congo - Kinshasa',
        code: '+243',
        flagImagePos: '-1px -1990px',
    },
    {
        id: '5a09d08e-b6ab-4084-8350-1d97d504c222',
        iso: 'ck',
        name: 'Cook Islands',
        code: '+682',
        flagImagePos: '-1px -3112px',
    },
    {
        id: '760f2b33-0822-4ad9-83cf-b497dcf273bb',
        iso: 'cr',
        name: 'Costa Rica',
        code: '+506',
        flagImagePos: '-1px -2857px',
    },
    {
        id: '489db55f-6316-4f43-a1c7-a0921e16743a',
        iso: 'ci',
        name: 'Côte d’Ivoire',
        code: '+225',
        flagImagePos: '-1px -2194px',
    },
    {
        id: '398c1d99-7ee4-44cd-9c2a-067acba2c8fb',
        iso: 'hr',
        name: 'Croatia',
        code: '+385',
        flagImagePos: '-1px -1174px',
    },
    {
        id: '572da7dc-8463-4797-ad84-7fcf8f53bb80',
        iso: 'cu',
        name: 'Cuba',
        code: '+53',
        flagImagePos: '-1px -987px',
    },
    {
        id: '572674e5-b0d4-4206-8310-70f4656e65e2',
        iso: 'cw',
        name: 'Curaçao',
        code: '+599',
        flagImagePos: '-1px -3758px',
    },
    {
        id: 'ac1e2a9d-a888-427e-9ad3-a0cbb27e603a',
        iso: 'cy',
        name: 'Cyprus',
        code: '+357',
        flagImagePos: '-1px -732px',
    },
    {
        id: '075ce3fd-83e7-472a-89cb-8b5e224102c4',
        iso: 'cz',
        name: 'Czechia',
        code: '+420',
        flagImagePos: '-1px -3095px',
    },
    {
        id: '4cde631a-97e9-4fc2-9465-9d9a433ca5c1',
        iso: 'dk',
        name: 'Denmark',
        code: '+45',
        flagImagePos: '-1px -1820px',
    },
    {
        id: '1b9c40a6-bf03-4759-b6ab-8edefafd8b44',
        iso: 'dj',
        name: 'Djibouti',
        code: '+253',
        flagImagePos: '-1px -2874px',
    },
    {
        id: 'f5eec2ba-1a0b-465c-b3e5-9bd8458d0704',
        iso: 'dm',
        name: 'Dominica',
        code: '+1',
        flagImagePos: '-1px -3350px',
    },
    {
        id: 'cb6921fc-df2a-4a97-8a34-4d901ac1e994',
        iso: 'do',
        name: 'Dominican Republic',
        code: '+1',
        flagImagePos: '-1px -2007px',
    },
    {
        id: '7d6641f1-ef97-4bee-b1b8-0f54fea35aeb',
        iso: 'ec',
        name: 'Ecuador',
        code: '+593',
        flagImagePos: '-1px -1531px',
    },
    {
        id: 'dfeb30b9-b4b8-4931-9334-c3961b7843a6',
        iso: 'eg',
        name: 'Egypt',
        code: '+20',
        flagImagePos: '-1px -3027px',
    },
    {
        id: '7d9f7158-7206-491f-a614-6a3e7e6af354',
        iso: 'sv',
        name: 'El Salvador',
        code: '+503',
        flagImagePos: '-1px -2160px',
    },
    {
        id: 'bcdbebc2-a51d-4891-93b0-52b463d0841d',
        iso: 'gq',
        name: 'Equatorial Guinea',
        code: '+240',
        flagImagePos: '-1px -1973px',
    },
    {
        id: '53c2c225-f321-406f-b377-7c8b6720bcb4',
        iso: 'er',
        name: 'Eritrea',
        code: '+291',
        flagImagePos: '-1px -936px',
    },
    {
        id: 'ba0e995a-17a8-48ff-88e6-54ff8207b038',
        iso: 'ee',
        name: 'Estonia',
        code: '+372',
        flagImagePos: '-1px -3333px',
    },
    {
        id: 'abe9af9b-91da-4bba-9adf-a496bf414719',
        iso: 'sz',
        name: 'Eswatini',
        code: '+268',
        flagImagePos: '-1px -3129px',
    },
    {
        id: 'e993ecc8-732a-4446-8ab1-144c084f3192',
        iso: 'et',
        name: 'Ethiopia',
        code: '+251',
        flagImagePos: '-1px -3367px',
    },
    {
        id: '6c7aae9d-e18d-4d09-8467-7bb99d925768',
        iso: 'fk',
        name: 'Falkland Islands (Islas Malvinas)',
        code: '+500',
        flagImagePos: '-1px -3809px',
    },
    {
        id: '92e704eb-9573-4d91-b932-2b1eddaacb3e',
        iso: 'fo',
        name: 'Faroe Islands',
        code: '+298',
        flagImagePos: '-1px -1429px',
    },
    {
        id: '561c079c-69c2-4e62-b947-5cd76783a67c',
        iso: 'fj',
        name: 'Fiji',
        code: '+679',
        flagImagePos: '-1px -2500px',
    },
    {
        id: '3f31a88e-c7ed-47fa-9aae-2058be7cbe09',
        iso: 'fi',
        name: 'Finland',
        code: '+358',
        flagImagePos: '-1px -2568px',
    },
    {
        id: '4c8ba1fc-0203-4a8f-8321-4dda4a0c6732',
        iso: 'fr',
        name: 'France',
        code: '+33',
        flagImagePos: '-1px -324px',
    },
    {
        id: '198074d5-67a2-4fd3-b13d-429a394b6371',
        iso: 'gf',
        name: 'French Guiana',
        code: '+594',
        flagImagePos: '-1px -324px',
    },
    {
        id: '2f5ff3d1-745e-48a1-b4e8-a377b22af812',
        iso: 'pf',
        name: 'French Polynesia',
        code: '+689',
        flagImagePos: '-1px -2262px',
    },
    {
        id: 'a8b80121-5529-4cfe-83fb-6b1f6c81abcb',
        iso: 'ga',
        name: 'Gabon',
        code: '+241',
        flagImagePos: '-1px -1157px',
    },
    {
        id: 'c9bc7d57-7883-4f63-bc6e-5dcc3db8612d',
        iso: 'gm',
        name: 'Gambia',
        code: '+220',
        flagImagePos: '-1px -817px',
    },
    {
        id: '1fc146d8-cebe-4ef1-bb0f-30bd0870ccf9',
        iso: 'ge',
        name: 'Georgia',
        code: '+995',
        flagImagePos: '-1px -1123px',
    },
    {
        id: 'e74ac4b1-0b4b-4630-bac0-2e53e270b363',
        iso: 'de',
        name: 'Germany',
        code: '+49',
        flagImagePos: '-1px -3452px',
    },
    {
        id: 'adda89c9-4b47-4552-85c4-668f2cef2dbd',
        iso: 'gh',
        name: 'Ghana',
        code: '+233',
        flagImagePos: '-1px -2891px',
    },
    {
        id: '962a059b-a5ac-4e2f-9405-5c418cadb6b0',
        iso: 'gi',
        name: 'Gibraltar',
        code: '+350',
        flagImagePos: '-1px -341px',
    },
    {
        id: '0acd0dae-0f39-4c23-be1d-c0295539d8c4',
        iso: 'gr',
        name: 'Greece',
        code: '+30',
        flagImagePos: '-1px -188px',
    },
    {
        id: '7529a6e4-8a6a-4c27-885e-ff0c5e15e515',
        iso: 'gl',
        name: 'Greenland',
        code: '+299',
        flagImagePos: '-1px -2347px',
    },
    {
        id: '416ba85d-f860-48dc-9c60-32602c07e266',
        iso: 'gd',
        name: 'Grenada',
        code: '+1',
        flagImagePos: '-1px -3316px',
    },
    {
        id: 'f43f1f96-1fb1-4e5e-b818-71e60e501fd4',
        iso: 'gp',
        name: 'Guadeloupe',
        code: '+590',
        flagImagePos: '-1px -511px',
    },
    {
        id: 'e29122da-20cf-4d24-bc68-93f9c3296730',
        iso: 'gu',
        name: 'Guam',
        code: '+1',
        flagImagePos: '-1px -3265px',
    },
    {
        id: '8a24ff28-dcae-4846-b0c1-18cfcb04de06',
        iso: 'gt',
        name: 'Guatemala',
        code: '+502',
        flagImagePos: '-1px -1208px',
    },
    {
        id: 'b617a005-be15-49c8-9533-c0376681a564',
        iso: 'gn',
        name: 'Guinea',
        code: '+224',
        flagImagePos: '-1px -3520px',
    },
    {
        id: 'd9913e74-e340-4a4f-bf4b-aaaf1747364b',
        iso: 'gw',
        name: 'Guinea-Bissau',
        code: '+245',
        flagImagePos: '-1px -2602px',
    },
    {
        id: 'c8245da4-cd4f-4818-a41e-42afec6faa9a',
        iso: 'gy',
        name: 'Guyana',
        code: '+592',
        flagImagePos: '-1px -1038px',
    },
    {
        id: 'c598961d-3040-4dbb-8934-6d8eb4b9be97',
        iso: 'ht',
        name: 'Haiti',
        code: '+509',
        flagImagePos: '-1px -392px',
    },
    {
        id: 'f51aadf1-3c7a-4d24-b8fb-69c7e05243e4',
        iso: 'hn',
        name: 'Honduras',
        code: '+504',
        flagImagePos: '-1px -2959px',
    },
    {
        id: 'a621dbe5-fb11-4f7f-9a8d-2330bd20c563',
        iso: 'hk',
        name: 'Hong Kong',
        code: '+852',
        flagImagePos: '-1px -3707px',
    },
    {
        id: 'a113fe26-d409-4ab7-b27c-0e8ac112071f',
        iso: 'hu',
        name: 'Hungary',
        code: '+36',
        flagImagePos: '-1px -902px',
    },
    {
        id: '6430b612-4071-4614-bfdb-408fbb0b8fa4',
        iso: 'is',
        name: 'Iceland',
        code: '+354',
        flagImagePos: '-1px -2704px',
    },
    {
        id: '4cce1334-df1f-4b11-9f15-a4faaac3d0db',
        iso: 'in',
        name: 'India',
        code: '+91',
        flagImagePos: '-1px -2245px',
    },
    {
        id: '54969b2f-6aa9-4a58-850d-b4779ef3038e',
        iso: 'id',
        name: 'Indonesia',
        code: '+62',
        flagImagePos: '-1px -2653px',
    },
    {
        id: 'cb631628-5854-44d2-9dbc-47cdf9c9ea5e',
        iso: 'ir',
        name: 'Iran',
        code: '+98',
        flagImagePos: '-1px -2738px',
    },
    {
        id: '21a50cc1-954c-49c2-8296-696f1f57b79e',
        iso: 'iq',
        name: 'Iraq',
        code: '+964',
        flagImagePos: '-1px -851px',
    },
    {
        id: '3e17cb8a-9c44-4c75-b417-556546ceebff',
        iso: 'ie',
        name: 'Ireland',
        code: '+353',
        flagImagePos: '-1px -2670px',
    },
    {
        id: '0a15f5a3-7571-478a-9fcd-6cbd6563e08c',
        iso: 'il',
        name: 'Israel',
        code: '+972',
        flagImagePos: '-1px -426px',
    },
    {
        id: '2cbab786-d79b-4ea1-ab26-0553c5e423d3',
        iso: 'it',
        name: 'Italy',
        code: '+39',
        flagImagePos: '-1px -154px',
    },
    {
        id: '33a67cd8-0858-46c3-b833-4fd395d2daa4',
        iso: 'jm',
        name: 'Jamaica',
        code: '+1',
        flagImagePos: '-1px -2296px',
    },
    {
        id: '5edf8bb6-6a29-44ee-b5f2-7d7cbf61f971',
        iso: 'jp',
        name: 'Japan',
        code: '+81',
        flagImagePos: '-1px -528px',
    },
    {
        id: '879b69bb-3f8f-484f-a767-7fdeef6bae15',
        iso: 'jo',
        name: 'Jordan',
        code: '+962',
        flagImagePos: '-1px -1905px',
    },
    {
        id: '4217e52c-2835-4c7b-87d3-e290c4fa6074',
        iso: 'kz',
        name: 'Kazakhstan',
        code: '+7',
        flagImagePos: '-1px -1565px',
    },
    {
        id: '934b172d-4427-47f6-8648-6411652be23d',
        iso: 'ke',
        name: 'Kenya',
        code: '+254',
        flagImagePos: '-1px -3605px',
    },
    {
        id: '2358e177-3956-4bcf-a954-56275e90e28d',
        iso: 'ki',
        name: 'Kiribati',
        code: '+686',
        flagImagePos: '-1px -477px',
    },
    {
        id: '98e8fae8-cd1b-419f-813b-ee348b51d843',
        iso: 'xk',
        name: 'Kosovo',
        code: '+383',
        flagImagePos: '-1px -3860px',
    },
    {
        id: '5376f774-4fcb-47dc-b118-e48d34b030ef',
        iso: 'kw',
        name: 'Kuwait',
        code: '+965',
        flagImagePos: '-1px -3435px',
    },
    {
        id: '9bc380c4-5840-4d26-a615-310cd817ae94',
        iso: 'kg',
        name: 'Kyrgyzstan',
        code: '+996',
        flagImagePos: '-1px -2143px',
    },
    {
        id: '3278e7f0-176b-4352-9e38-df59b052b91f',
        iso: 'la',
        name: 'Laos',
        code: '+856',
        flagImagePos: '-1px -562px',
    },
    {
        id: 'e2ba5fad-f531-467c-b195-a6cd90136e19',
        iso: 'lv',
        name: 'Latvia',
        code: '+371',
        flagImagePos: '-1px -2619px',
    },
    {
        id: '49f74ca5-9ff1-44af-8e9c-59e1c4704e83',
        iso: 'lb',
        name: 'Lebanon',
        code: '+961',
        flagImagePos: '-1px -1616px',
    },
    {
        id: 'd94b6d96-17c1-4de8-abc3-3e14873b62c0',
        iso: 'ls',
        name: 'Lesotho',
        code: '+266',
        flagImagePos: '-1px -3010px',
    },
    {
        id: 'e35005f8-285e-4fe5-9cda-def721d9cc7b',
        iso: 'lr',
        name: 'Liberia',
        code: '+231',
        flagImagePos: '-1px -2823px',
    },
    {
        id: '60788779-78f0-4b2b-8ad8-c7e4bbde10b5',
        iso: 'ly',
        name: 'Libya',
        code: '+218',
        flagImagePos: '-1px -137px',
    },
    {
        id: 'f24ad4ea-454a-4d40-a1f1-db188ec0b75e',
        iso: 'li',
        name: 'Liechtenstein',
        code: '+423',
        flagImagePos: '-1px -1276px',
    },
    {
        id: 'f6709b72-4150-4cde-a37b-e6eb95f5bd1d',
        iso: 'lt',
        name: 'Lithuania',
        code: '+370',
        flagImagePos: '-1px -1446px',
    },
    {
        id: '0d0c1a84-f645-4ffe-87d2-9a7bb4f88bbc',
        iso: 'lu',
        name: 'Luxembourg',
        code: '+352',
        flagImagePos: '-1px -1922px',
    },
    {
        id: '5b3fdebe-a4ed-47c6-88c3-d867d3a79bf0',
        iso: 'mo',
        name: 'Macao',
        code: '+853',
        flagImagePos: '-1px -3554px',
    },
    {
        id: '6a84f456-bc77-4b76-8651-e2a0994f3278',
        iso: 'mg',
        name: 'Madagascar',
        code: '+261',
        flagImagePos: '-1px -1667px',
    },
    {
        id: '2a5d5baf-1db7-4606-a330-227834c77098',
        iso: 'mw',
        name: 'Malawi',
        code: '+265',
        flagImagePos: '-1px -2942px',
    },
    {
        id: 'f2b32090-6d8d-40db-ba50-a63037926508',
        iso: 'my',
        name: 'Malaysia',
        code: '+60',
        flagImagePos: '-1px -2517px',
    },
    {
        id: '51c7830c-0c76-44ed-bcdf-be75688e1d0c',
        iso: 'mv',
        name: 'Maldives',
        code: '+960',
        flagImagePos: '-1px -800px',
    },
    {
        id: 'ea7a2274-0542-4bbb-b629-aa63bef97442',
        iso: 'ml',
        name: 'Mali',
        code: '+223',
        flagImagePos: '-1px -3469px',
    },
    {
        id: '6f70796e-8f64-4a1a-ac2a-990d7d502db3',
        iso: 'mt',
        name: 'Malta',
        code: '+356',
        flagImagePos: '-1px -2041px',
    },
    {
        id: 'c60f429e-0d4f-42cf-96f9-e7dc4fdcd5ee',
        iso: 'mh',
        name: 'Marshall Islands',
        code: '+692',
        flagImagePos: '-1px -1463px',
    },
    {
        id: 'e8afae89-e5b0-4551-bbd4-bbfcee50c8ad',
        iso: 'mq',
        name: 'Martinique',
        code: '+596',
        flagImagePos: '-1px -239px',
    },
    {
        id: '361afc7c-ee94-464b-b5cb-f059ecd79e99',
        iso: 'mr',
        name: 'Mauritania',
        code: '+222',
        flagImagePos: '-1px -307px',
    },
    {
        id: 'bce43b5e-d2f7-47ca-b5c9-9ae72ba67bda',
        iso: 'mu',
        name: 'Mauritius',
        code: '+230',
        flagImagePos: '-1px -2993px',
    },
    {
        id: 'd153dc32-4821-4f05-a5c8-564d003da5e1',
        iso: 'mx',
        name: 'Mexico',
        code: '+52',
        flagImagePos: '-1px -2755px',
    },
    {
        id: '80f9f386-231f-4d96-b950-5f6b6edbeb63',
        iso: 'fm',
        name: 'Micronesia',
        code: '+691',
        flagImagePos: '-1px -2313px',
    },
    {
        id: 'a1d89e32-4b91-4519-b0d9-7d61299394ef',
        iso: 'md',
        name: 'Moldova',
        code: '+373',
        flagImagePos: '-1px -3690px',
    },
    {
        id: '0afeb22c-c106-479b-af45-1380fb8b404c',
        iso: 'mc',
        name: 'Monaco',
        code: '+377',
        flagImagePos: '-1px -1191px',
    },
    {
        id: 'a18d0204-7c4a-425c-a33e-cbfac01be162',
        iso: 'mn',
        name: 'Mongolia',
        code: '+976',
        flagImagePos: '-1px -3503px',
    },
    {
        id: '260479fc-0410-4ccd-a963-e06c9f059bdb',
        iso: 'me',
        name: 'Montenegro',
        code: '+382',
        flagImagePos: '-1px -2976px',
    },
    {
        id: 'a66872f1-ba90-420f-8f55-f0fbb10abce1',
        iso: 'ms',
        name: 'Montserrat',
        code: '+1',
        flagImagePos: '-1px -749px',
    },
    {
        id: '8fd1ba13-cb1a-488d-b715-01724d56d9dd',
        iso: 'ma',
        name: 'Morocco',
        code: '+212',
        flagImagePos: '-1px -3214px',
    },
    {
        id: '5d26fba4-6d15-4cd4-a23f-9034d952e580',
        iso: 'mz',
        name: 'Mozambique',
        code: '+258',
        flagImagePos: '-1px -834px',
    },
    {
        id: 'f9c12031-14dc-495f-b150-28dddce17e3f',
        iso: 'mm',
        name: 'Myanmar (Burma)',
        code: '+95',
        flagImagePos: '-1px -18px',
    },
    {
        id: '6e21e956-2740-4058-a758-3b249f628a7b',
        iso: 'na',
        name: 'Namibia',
        code: '+264',
        flagImagePos: '-1px -2534px',
    },
    {
        id: '4a07dd5a-9341-4b06-969f-4bcd9c32e2a0',
        iso: 'nr',
        name: 'Nauru',
        code: '+674',
        flagImagePos: '-1px -2330px',
    },
    {
        id: '9d7121ce-1445-4c84-9401-ddc703d9dedb',
        iso: 'np',
        name: 'Nepal',
        code: '+977',
        flagImagePos: '-1px -120px',
    },
    {
        id: '31fbb24d-7c38-4ca8-b385-48d76a0685e3',
        iso: 'nl',
        name: 'Netherlands',
        code: '+31',
        flagImagePos: '-1px -1888px',
    },
    {
        id: '18071cc2-c457-4b4f-9217-2519a0b52c25',
        iso: 'nc',
        name: 'New Caledonia',
        code: '+687',
        flagImagePos: '-1px -1650px',
    },
    {
        id: 'c4b0e7d1-08b2-421b-8ff6-913020cbf271',
        iso: 'nz',
        name: 'New Zealand',
        code: '+64',
        flagImagePos: '-1px -2024px',
    },
    {
        id: '25719230-2c64-4525-96c4-d4427dd2e40b',
        iso: 'ni',
        name: 'Nicaragua',
        code: '+505',
        flagImagePos: '-1px -171px',
    },
    {
        id: 'a1090a0b-7f89-4d75-8c92-e460da9103ab',
        iso: 'ne',
        name: 'Niger',
        code: '+227',
        flagImagePos: '-1px -715px',
    },
    {
        id: '6869e4bb-32b8-43ff-84d1-67d9ee832e1f',
        iso: 'ng',
        name: 'Nigeria',
        code: '+234',
        flagImagePos: '-1px -3418px',
    },
    {
        id: '52b3ae35-196a-4e22-81e2-67b816a32d0e',
        iso: 'nu',
        name: 'Niue',
        code: '+683',
        flagImagePos: '-1px -2840px',
    },
    {
        id: '9f4e45d4-c7e1-4ba9-84d0-e712e7213c95',
        iso: 'nf',
        name: 'Norfolk Island',
        code: '+672',
        flagImagePos: '-1px -256px',
    },
    {
        id: '2db1b02c-631e-40a0-94d8-f1e567b1f705',
        iso: 'kp',
        name: 'North Korea',
        code: '+850',
        flagImagePos: '-1px -2415px',
    },
    {
        id: '92621b3f-55f5-42bb-8604-d0302e355e31',
        iso: 'mk',
        name: 'North Macedonia',
        code: '+389',
        flagImagePos: '-1px -1769px',
    },
    {
        id: '3cee8ab2-5cb3-43ea-b8ab-7016187d33e9',
        iso: 'mp',
        name: 'Northern Mariana Islands',
        code: '+1',
        flagImagePos: '-1px -919px',
    },
    {
        id: '77683fad-f106-4a94-a629-9562650edb35',
        iso: 'no',
        name: 'Norway',
        code: '+47',
        flagImagePos: '-1px -1089px',
    },
    {
        id: '09090411-ef9b-44f3-aeb9-65b5e338b8d6',
        iso: 'om',
        name: 'Oman',
        code: '+968',
        flagImagePos: '-1px -3384px',
    },
    {
        id: '18d4f06b-233b-4398-a9f8-6b4a4eaf6c71',
        iso: 'pk',
        name: 'Pakistan',
        code: '+92',
        flagImagePos: '-1px -2772px',
    },
    {
        id: 'b1da5023-aab9-431c-921c-4f3e12b1aa7a',
        iso: 'pw',
        name: 'Palau',
        code: '+680',
        flagImagePos: '-1px -273px',
    },
    {
        id: 'e6442ab2-ac99-4a02-9d7c-fd878e50de8a',
        iso: 'ps',
        name: 'Palestine',
        code: '+970',
        flagImagePos: '-1px -1548px',
    },
    {
        id: '6bb10fb5-8b4a-4136-a82e-6be6c017ab76',
        iso: 'pa',
        name: 'Panama',
        code: '+507',
        flagImagePos: '-1px -1106px',
    },
    {
        id: 'b070a014-2ce4-4939-a868-951bd1e70923',
        iso: 'pg',
        name: 'Papua New Guinea',
        code: '+675',
        flagImagePos: '-1px -1939px',
    },
    {
        id: '5e23c743-ce7d-4abc-9dd4-44a700b29090',
        iso: 'py',
        name: 'Paraguay',
        code: '+595',
        flagImagePos: '-1px -3231px',
    },
    {
        id: '1a83f99d-91b3-438d-a576-5bf0f05fdd12',
        iso: 'pe',
        name: 'Peru',
        code: '+51',
        flagImagePos: '-1px -1225px',
    },
    {
        id: '667c9699-46b9-40f9-a41f-2c52826bb3cb',
        iso: 'ph',
        name: 'Philippines',
        code: '+63',
        flagImagePos: '-1px -2432px',
    },
    {
        id: 'b84030ab-3193-4aa2-aef2-d4d21997e536',
        iso: 'pl',
        name: 'Poland',
        code: '+48',
        flagImagePos: '-1px -1514px',
    },
    {
        id: 'e26d0064-6173-42ab-b761-bf8c639199fa',
        iso: 'pt',
        name: 'Portugal',
        code: '+351',
        flagImagePos: '-1px -664px',
    },
    {
        id: '0fd9770d-2a91-4b81-8633-f465bc151e16',
        iso: 'pr',
        name: 'Puerto Rico',
        code: '+1',
        flagImagePos: '-1px -596px',
    },
    {
        id: 'f866eeeb-e64f-4123-ab63-c16e0a00d029',
        iso: 'qa',
        name: 'Qatar',
        code: '+974',
        flagImagePos: '-1px -579px',
    },
    {
        id: 'c3a3fb54-5731-4a28-96bd-4190cfeeaff0',
        iso: 're',
        name: 'Réunion',
        code: '+262',
        flagImagePos: '-1px -324px',
    },
    {
        id: 'a6a48809-7e33-42c8-a25a-56ccdd7ccdfe',
        iso: 'ro',
        name: 'Romania',
        code: '+40',
        flagImagePos: '-1px -885px',
    },
    {
        id: '9556d1e9-3d02-4c5b-a0ce-97a2fd55c74a',
        iso: 'ru',
        name: 'Russia',
        code: '+7',
        flagImagePos: '-1px -868px',
    },
    {
        id: '6f7f0a97-e8b5-455d-bace-6953de7324eb',
        iso: 'rw',
        name: 'Rwanda',
        code: '+250',
        flagImagePos: '-1px -3673px',
    },
    {
        id: 'e251cad5-7655-48f7-9892-6edf04a14fd7',
        iso: 'ws',
        name: 'Samoa',
        code: '+685',
        flagImagePos: '-1px -3163px',
    },
    {
        id: 'f1cfec8c-a960-43b3-8e11-2cad72b4fff8',
        iso: 'sm',
        name: 'San Marino',
        code: '+378',
        flagImagePos: '-1px -2908px',
    },
    {
        id: 'c5301260-13dc-4012-9678-2b57a5e409ae',
        iso: 'st',
        name: 'São Tomé & Príncipe',
        code: '+239',
        flagImagePos: '-1px -3299px',
    },
    {
        id: '02599f80-225a-451b-8c25-03b8993f88ac',
        iso: 'sa',
        name: 'Saudi Arabia',
        code: '+966',
        flagImagePos: '-1px -52px',
    },
    {
        id: 'a54c3469-9668-4063-bfa0-04c450b43d3e',
        iso: 'sn',
        name: 'Senegal',
        code: '+221',
        flagImagePos: '-1px -2925px',
    },
    {
        id: '687ea07b-a7df-4778-b802-b040676fa56c',
        iso: 'rs',
        name: 'Serbia',
        code: '+381',
        flagImagePos: '-1px -3401px',
    },
    {
        id: 'd010fb25-7044-4055-9c60-25bc89d83f64',
        iso: 'sc',
        name: 'Seychelles',
        code: '+248',
        flagImagePos: '-1px -1327px',
    },
    {
        id: '0c46a1e9-fcd8-4e7e-bbb1-ef3bfa83539b',
        iso: 'sl',
        name: 'Sierra Leone',
        code: '+232',
        flagImagePos: '-1px -970px',
    },
    {
        id: 'e724edb6-9df4-42fb-bc1e-417996aa3020',
        iso: 'sg',
        name: 'Singapore',
        code: '+65',
        flagImagePos: '-1px -35px',
    },
    {
        id: '7478814a-dc3f-41ff-9341-da7e07ba8499',
        iso: 'sx',
        name: 'Sint Maarten',
        code: '+1',
        flagImagePos: '-1px -3826px',
    },
    {
        id: 'b1a34e32-38dd-4a38-b63a-7133baf1417a',
        iso: 'sk',
        name: 'Slovakia',
        code: '+421',
        flagImagePos: '-1px -3044px',
    },
    {
        id: '1c1689a5-580b-411f-9283-b1e8333b351e',
        iso: 'si',
        name: 'Slovenia',
        code: '+386',
        flagImagePos: '-1px -1582px',
    },
    {
        id: '4b1c6a42-90b0-49ea-b968-8c95b871f0ec',
        iso: 'sb',
        name: 'Solomon Islands',
        code: '+677',
        flagImagePos: '-1px -1361px',
    },
    {
        id: '7ec9fdff-8ae6-4a14-b55e-6262d46bc3ef',
        iso: 'so',
        name: 'Somalia',
        code: '+252',
        flagImagePos: '-1px -1786px',
    },
    {
        id: '5e62f404-3e2c-4d63-ad7b-ab0755903842',
        iso: 'za',
        name: 'South Africa',
        code: '+27',
        flagImagePos: '-1px -3248px',
    },
    {
        id: '31966c2a-7d24-4ebc-8e02-392e4f04f12b',
        iso: 'kr',
        name: 'South Korea',
        code: '+82',
        flagImagePos: '-1px -3078px',
    },
    {
        id: '1b7ba825-bf7d-42c0-bb73-81f10a4009bf',
        iso: 'ss',
        name: 'South Sudan',
        code: '+211',
        flagImagePos: '-1px -3775px',
    },
    {
        id: '55c4137b-e437-4e80-bc8f-7857cd7c9364',
        iso: 'es',
        name: 'Spain',
        code: '+34',
        flagImagePos: '-1px -1480px',
    },
    {
        id: 'fce4c284-e6a1-4e8c-96ca-6edf09e8a401',
        iso: 'lk',
        name: 'Sri Lanka',
        code: '+94',
        flagImagePos: '-1px -3622px',
    },
    {
        id: '0ae719a5-ae43-45d0-b669-66976a050ef1',
        iso: 'bl',
        name: 'St. Barthélemy',
        code: '+590',
        flagImagePos: '-1px -324px',
    },
    {
        id: 'a588cc85-32a4-45ff-ba69-627105dab27a',
        iso: 'sh',
        name: 'St. Helena',
        code: '+290',
        flagImagePos: '-1px -630px',
    },
    {
        id: 'f065aa7c-8d9e-419c-bbf0-9a97011cf272',
        iso: 'kn',
        name: 'St. Kitts & Nevis',
        code: '+1',
        flagImagePos: '-1px -103px',
    },
    {
        id: '9ea73bcc-2bf5-4ad9-9b39-de33de125f98',
        iso: 'lc',
        name: 'St. Lucia',
        code: '+1',
        flagImagePos: '-1px -1837px',
    },
    {
        id: '86a5a0e8-bfd4-480e-9bc0-7b88b2248a57',
        iso: 'mf',
        name: 'St. Martin',
        code: '+590',
        flagImagePos: '-1px -86px',
    },
    {
        id: '540857ba-923a-4656-a19f-cb3914825ecc',
        iso: 'pm',
        name: 'St. Pierre & Miquelon',
        code: '+508',
        flagImagePos: '-1px -1378px',
    },
    {
        id: 'd381eb44-e77a-4dbd-abbb-224d7158e96d',
        iso: 'vc',
        name: 'St. Vincent & Grenadines',
        code: '+1',
        flagImagePos: '-1px -3588px',
    },
    {
        id: '7015db62-072d-49a2-8320-7587ec8b952f',
        iso: 'sd',
        name: 'Sudan',
        code: '+249',
        flagImagePos: '-1px -443px',
    },
    {
        id: 'd7bbb285-aa4e-4a92-8613-8d2645c351ee',
        iso: 'sr',
        name: 'Suriname',
        code: '+597',
        flagImagePos: '-1px -3656px',
    },
    {
        id: '78978092-7be3-4ec8-b201-068089035cff',
        iso: 'se',
        name: 'Sweden',
        code: '+46',
        flagImagePos: '-1px -494px',
    },
    {
        id: '9f3fbec3-b58a-4b5a-9c4b-3997398c4148',
        iso: 'ch',
        name: 'Switzerland',
        code: '+41',
        flagImagePos: '-1px -1718px',
    },
    {
        id: '7ce0562c-fdc4-444c-bba3-02239c3c17da',
        iso: 'sy',
        name: 'Syria',
        code: '+963',
        flagImagePos: '-1px -2449px',
    },
    {
        id: '2d57a4a1-3f5a-41a2-a320-74a8f0db92e5',
        iso: 'tw',
        name: 'Taiwan',
        code: '+886',
        flagImagePos: '-1px -647px',
    },
    {
        id: 'e1f747c5-4e91-487b-8265-8f70b3430849',
        iso: 'tj',
        name: 'Tajikistan',
        code: '+992',
        flagImagePos: '-1px -222px',
    },
    {
        id: 'f07e257c-e049-4046-b031-f4348fb1734a',
        iso: 'tz',
        name: 'Tanzania',
        code: '+255',
        flagImagePos: '-1px -3146px',
    },
    {
        id: '684a0dde-5b5f-4072-98a4-46fc8de09556',
        iso: 'th',
        name: 'Thailand',
        code: '+66',
        flagImagePos: '-1px -1242px',
    },
    {
        id: '0376e29f-d9dd-4449-aa4e-d47353c16873',
        iso: 'tl',
        name: 'Timor-Leste',
        code: '+670',
        flagImagePos: '-1px -3843px',
    },
    {
        id: 'fd647814-fc64-4724-bba7-4cd4da26c11e',
        iso: 'tg',
        name: 'Togo',
        code: '+228',
        flagImagePos: '-1px -783px',
    },
    {
        id: 'ed271b14-39ee-4403-9be6-b54ac89b0ed3',
        iso: 'tk',
        name: 'Tokelau',
        code: '+690',
        flagImagePos: '-1px -3792px',
    },
    {
        id: 'e2b83ecb-5a79-4ca0-9860-4baeae0380bb',
        iso: 'to',
        name: 'Tonga',
        code: '+676',
        flagImagePos: '-1px -1395px',
    },
    {
        id: '33bca09c-cc33-4680-929b-191ccbbc959a',
        iso: 'tt',
        name: 'Trinidad & Tobago',
        code: '+1',
        flagImagePos: '-1px -545px',
    },
    {
        id: 'ab25c5da-7698-4b96-af34-5d20523915d9',
        iso: 'tn',
        name: 'Tunisia',
        code: '+216',
        flagImagePos: '-1px -698px',
    },
    {
        id: '784ac645-bc50-4b35-b5fb-effd72f99749',
        iso: 'tr',
        name: 'Turkey',
        code: '+90',
        flagImagePos: '-1px -2126px',
    },
    {
        id: '9a3b8bd3-bc73-4251-a068-a4842365e91a',
        iso: 'tm',
        name: 'Turkmenistan',
        code: '+993',
        flagImagePos: '-1px -3486px',
    },
    {
        id: '361bcad4-44d1-41fb-9bbf-39ea0fb87d49',
        iso: 'tc',
        name: 'Turks & Caicos Islands',
        code: '+1',
        flagImagePos: '-1px -1701px',
    },
    {
        id: '26fb1484-c756-4592-8523-99af9c870bb5',
        iso: 'tv',
        name: 'Tuvalu',
        code: '+688',
        flagImagePos: '-1px -358px',
    },
    {
        id: 'cdb8455e-4eda-48f7-b30a-63c20838a364',
        iso: 'vi',
        name: 'U.S. Virgin Islands',
        code: '+1',
        flagImagePos: '-1px -2381px',
    },
    {
        id: 'f47476cc-3da6-4377-83c9-33ab9f5293d1',
        iso: 'ug',
        name: 'Uganda',
        code: '+256',
        flagImagePos: '-1px -1497px',
    },
    {
        id: '5fcb791a-91be-416a-895d-0502fc509838',
        iso: 'ua',
        name: 'Ukraine',
        code: '+380',
        flagImagePos: '-1px -2721px',
    },
    {
        id: '7c8e1ced-0dd7-42b6-880b-19b3486d11e5',
        iso: 'ae',
        name: 'United Arab Emirates',
        code: '+971',
        flagImagePos: '-1px -3061px',
    },
    {
        id: '9f1362e7-e87c-4123-ade8-e5cfa6e99c09',
        iso: 'gb',
        name: 'United Kingdom',
        code: '+44',
        flagImagePos: '-1px -86px',
    },
    {
        id: 'f9033267-9df0-46e4-9f79-c8b022e5c835',
        iso: 'us',
        name: 'United States',
        code: '+1',
        flagImagePos: '-1px -69px',
    },
    {
        id: '2cab7122-ec9a-48ac-8415-392b4f67ae51',
        iso: 'uy',
        name: 'Uruguay',
        code: '+598',
        flagImagePos: '-1px -3571px',
    },
    {
        id: 'f442740c-94c3-4f2f-afb2-c7c279224b5f',
        iso: 'uz',
        name: 'Uzbekistan',
        code: '+998',
        flagImagePos: '-1px -1293px',
    },
    {
        id: 'e6774547-6ab1-41a2-8107-201f913937b2',
        iso: 'vu',
        name: 'Vanuatu',
        code: '+678',
        flagImagePos: '-1px -1633px',
    },
    {
        id: 'd600d6b0-e21f-4b6e-9036-0435a6ac2ea6',
        iso: 'va',
        name: 'Vatican City',
        code: '+39',
        flagImagePos: '-1px -3197px',
    },
    {
        id: 'b8e0072d-498b-4bb4-a5b6-354d4200f882',
        iso: 've',
        name: 'Venezuela',
        code: '+58',
        flagImagePos: '-1px -1344px',
    },
    {
        id: '15dc081a-4690-42e9-a40d-b3bcea3173fc',
        iso: 'vn',
        name: 'Vietnam',
        code: '+84',
        flagImagePos: '-1px -1259px',
    },
    {
        id: '4452a787-5f31-4eb7-b14c-ae3175564ae5',
        iso: 'wf',
        name: 'Wallis & Futuna',
        code: '+681',
        flagImagePos: '-1px -324px',
    },
    {
        id: '237c9f8d-3b6c-4b70-af72-8a58a7154144',
        iso: 'ye',
        name: 'Yemen',
        code: '+967',
        flagImagePos: '-1px -2211px',
    },
    {
        id: '02a76f62-3078-472a-bd42-edb759cf3079',
        iso: 'zm',
        name: 'Zambia',
        code: '+260',
        flagImagePos: '-1px -2109px',
    },
    {
        id: '10e8e117-6832-4d3f-9b05-f66832c2f5ec',
        iso: 'zw',
        name: 'Zimbabwe',
        code: '+263',
        flagImagePos: '-1px -2789px',
    },
];
export const tags = [
    {
        id: 'c31e9e5d-e0cb-4574-a13f-8a6ee5ff8309',
        title: 'Work',
    },
    {
        id: 'a8991c76-2fda-4bbd-a718-df13d6478847',
        title: 'Friend',
    },
    {
        id: '56ddbd47-4078-4ddd-8448-73c5e88d5f59',
        title: 'Family',
    },
    {
        id: '2026ce08-d08f-4b4f-9506-b10cdb5b104f',
        title: 'High School',
    },
    {
        id: '65930b5a-5d2a-4303-b11f-865d69e6fdb5',
        title: 'College',
    },
    {
        id: '3eaab175-ec0d-4db7-bc3b-efc633c769be',
        title: 'Baseball Team',
    },
    {
        id: 'cbde2486-5033-4e09-838e-e901b108cd41',
        title: 'Band',
    },
];
