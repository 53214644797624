import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/admin',
    },
    {
        id: 'applications',
        title: 'Applications',
        type: 'basic',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/applications',
    },
    {
        id: 'drivers',
        title: 'Drivers',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/drivers',
    },
    {
        id: 'curriculums',
        title: 'Curriculums',
        type: 'basic',
        icon: 'heroicons_outline:academic-cap',
        link: '/curriculums',
    },
    {
        id: 'downloads',
        title: 'Downloads',
        type: 'basic',
        icon: 'heroicons_outline:arrow-down-tray',
        link: '/downloads',
    },
    {
        id: 'documentations',
        title: 'Documentations',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/user-documentations',
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:presentation-chart-line',
        link: '/reports',
    },
    {
        id: 'account',
        title: 'My Account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/account',
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'applications',
        title: 'Applications',
        type: 'basic',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/applications',
    },
    {
        id: 'drivers',
        title: 'Drivers',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/drivers',
    },
    {
        id: 'curriculums',
        title: 'Curriculums',
        type: 'basic',
        icon: 'heroicons_outline:academic-cap',
        link: '/curriculums',
    },
    {
        id: 'downloads',
        title: 'Downloads',
        type: 'basic',
        icon: 'heroicons_outline:arrow-down-tray',
        link: '/downloads',
    },
    {
        id: 'documentations',
        title: 'Documentations',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/documentations',
    },
    {
        id: 'account',
        title: 'My Account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/account',
    },
];

export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'applications',
        title: 'Applications',
        type: 'basic',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/applications',
    },
    {
        id: 'drivers',
        title: 'Drivers',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/drivers',
    },
    {
        id: 'curriculums',
        title: 'Curriculums',
        type: 'basic',
        icon: 'heroicons_outline:academic-cap',
        link: '/curriculums',
    },
    {
        id: 'downloads',
        title: 'Downloads',
        type: 'basic',
        icon: 'heroicons_outline:arrow-down-tray',
        link: '/downloads',
    },
    {
        id: 'documentations',
        title: 'Documentations',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/documentations',
    },
    {
        id: 'account',
        title: 'My Account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/account',
    },
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'applications',
        title: 'Applications',
        type: 'basic',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/applications',
    },
    {
        id: 'drivers',
        title: 'Drivers',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/drivers',
    },
    {
        id: 'curriculums',
        title: 'Curriculums',
        type: 'basic',
        icon: 'heroicons_outline:academic-cap',
        link: '/curriculums',
    },
    {
        id: 'downloads',
        title: 'Downloads',
        type: 'basic',
        icon: 'heroicons_outline:arrow-down-tray',
        link: '/downloads',
    },
    {
        id: 'documentations',
        title: 'Documentations',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/documentations',
    },
    {
        id: 'account',
        title: 'My Account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/account',
    },
];
