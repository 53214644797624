import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { Amplify } from 'aws-amplify';
import { environment } from './environments/environment';
Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: environment.aws_user_pools_id,
            userPoolClientId: environment.aws_user_pools_web_client_id,
            identityPoolId: environment.aws_cognito_identity_pool_id,
            loginWith: {
                email: true,
            },
            signUpVerificationMethod: 'code',
            userAttributes: {
                email: {
                    required: true,
                },
            },
            allowGuestAccess: true,
            passwordFormat: {
                minLength: 8,
                requireLowercase: true,
                requireUppercase: true,
                requireNumbers: true,
                requireSpecialCharacters: true,
            },
        },
    },
});
bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
);
